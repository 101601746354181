import { Container } from "components/Card";
import { UserDatePicker } from "components/DateControl";
import { downloadFileFromUrl, getMomentDate } from "libs/functions";
import useForm from "hooks/useForm";
import { useDispatch } from "react-redux";
import { UserButton } from "components/Button";
import { AiOutlineFileExcel } from "react-icons/ai";
import { getQuizParticipantDetails } from "actions/report";

const QuizParticipantDetailScreen = () => {
  const dispatch = useDispatch();
  const { state, handleChange } = useForm({
    startDate: getMomentDate(new Date()).add(-30, "days"),
    endDate: getMomentDate(new Date()),
  });
  const { startDate, endDate } = state;

  const handleDownload = () => {
    dispatch(
      getQuizParticipantDetails(state, ({ filePath, fileName }) => {
        downloadFileFromUrl(filePath, fileName);
      })
    );
  };

  return (
    <Container title="Quiz  and participant details">
      <div className="row">
        <div className="col-6">
          <UserDatePicker
            name="startDate"
            value={startDate}
            label="Start Date"
            onChange={handleChange}
          />
        </div>
        <div className="col-6">
          <UserDatePicker
            name="endDate"
            value={endDate}
            label="End Date"
            onChange={handleChange}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <UserButton
            text="Download"
            onClick={handleDownload}
            icon={<AiOutlineFileExcel />}
          />
        </div>
      </div>
    </Container>
  );
};

export default QuizParticipantDetailScreen;
