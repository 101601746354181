export const RouteConstant = {
  LOGIN: "/account/login",
  FORGOT_PASSWORD: "/account/forgotPassword",
  RESET_PASSWORD: "/account/resetPassword",
  SIGN_UP_PARTICIPANT: "/account/participant/signUp",
  SIGN_UP: "/account/signUp",
  VERIFY_ACCOUNT: "/account/verify",
  QUIZ_CODE: "/account/gameCode",
  SUBSCRIPTION: "/admin/subscription",
  PREDEFINED_QUIZZES: "/admin/predefinedGames",
  ADD_PREDEFINED_QUIZ: "/admin/addPredefinedGames",
  QUIZ_MASTER_LIST: "/admin/user",
  TEST_QUIZZES: "/admin/games",
  QUIZZES: "/user/games",
  ADD__QUIZ: "/user/addGames",
  REPORT: "/user/report",
  QUIZ_PARTICIPANT_DETAILS: "/user/quizParticipants",
  PARTICIPANT_LIST: "/user/participant",
  HOST_QUIZ: "/game/hostGame",
  QUIZ_PARTICIPANTS: "/game/participant",
  TEST_QUIZ_PARTICIPANTS: "/test/game/participant",
  QUIZ_PARTICIPANT_RANK: "/game/participantRank",
  QUIZ_PARTICIPANT_RESULT: "/game/participantResult",
  USER_QUIZ_CODE: "/user/gameCode",
  USER_QUIZ_QUESTIONS: "/game/participant/questions",
};
