import React from "react";
import PropTypes from "prop-types";
import { UserError, UserLabel } from "../Label";
import { AppConstant } from "../../utils/constant";

const Select = (props) => {
  const {
    name,
    placeholder,
    className,
    disabled,
    addFirstItem,
    value,
    options,
    error,
    onChange,
    tabIndex,
  } = props;

  return (
    <select
      id={name}
      className={`form-control ${className ? `${className}` : ""} ${
        error ? "errInput" : ""
      }`}
      name={name}
      value={value == null ? "" : value}
      onChange={onChange}
      autoComplete="off"
      disabled={disabled}
      tabIndex={tabIndex}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {addFirstItem && <option value="">{AppConstant.FIRST_ITEM_TEXT}</option>}
      {options &&
        options.map((option, index) => (
          <option key={index} value={option.value} {...option.props}>
            {option.text}
          </option>
        ))}
    </select>
  );
};

const UserSelect = (props) => {
  const onFieldChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    props.onChange(name, value);
  };

  const { errors, label, name } = props;
  const error = errors ? errors[name] : null;

  return (
    <div className="form-group form-md-line-input has-info">
      <Select {...props} onChange={onFieldChange} error={error} />
      {label && <UserLabel {...props} />}
      <UserError error={error} />
    </div>
  );
};

UserSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default UserSelect;
