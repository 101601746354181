import { fork } from "redux-saga/effects";
import account from "./account";
import subscription from "./subscription";
import quiz from "./quiz";
import user from "./user";
import report from "./report";

export default function* root() {
  yield fork(account);
  yield fork(subscription);
  yield fork(quiz);
  yield fork(user);
  yield fork(report);
}
