export const Quiz = {
  ADD_QUIZ_VIEW: "ADD_QUIZ_VIEW",
  GET_PREDEFINED_QUIZZES: "GET_PREDEFINED_QUIZZES",
  GET_ALL_QUIZZES: "GET_ALL_QUIZZES",
  GET_QUIZ_LIST: "GET_QUIZ_LIST",
  ADD_EDIT_PREDEFINED_QUIZ: "ADD_EDIT_PREDEFINED_QUIZ",
  UPLOAD_QUESTION_FILE: "UPLOAD_QUESTION_FILE",
  GET_PREDEFINED_QUIZ_DETAILS: "GET_PREDEFINED_QUIZ_DETAILS",
  HOST_QUIZ: "HOST_QUIZ",
  GET_HOSTED_QUIZ_DETAIL: "GET_HOSTED_QUIZ_DETAIL",
  GET_QUIZ_PARTICIPANTS: "GET_QUIZ_PARTICIPANTS",
  GET_QUIZ_PARTICIPANT_RANKS: "GET_QUIZ_PARTICIPANT_RANKS",
  VALIDATE_QUIZ_CODE: "VALIDATE_QUIZ_CODE",
  START_QUIZ: "START_QUIZ",
  FINISH_QUIZ: "FINISH_QUIZ",
  QUIZ_SUCCESS: "QUIZ_SUCCESS",
};
