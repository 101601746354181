import { getPredefinedQuizzes } from "actions/quiz";
import { Container } from "components/Card";
import { TextInput } from "components/TextInput";
import useForm from "hooks/useForm";
import { containsString } from "libs/functions";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { QuizGrid } from "./Components";

export const GridContainer = ({
  title,
  quizList: initQuizList,
  isDisabled,
  wrapperClass,
  isTestQuiz,
}) => {
  const [quizList, setQuizList] = useState([]);
  const { state, handleChange } = useForm({});
  const { searchBy } = state;

  useEffect(() => {
    let quizList = initQuizList;
    if (searchBy && quizList && quizList.length > 0) {
      const keys = Object.keys(quizList[0]);
      quizList = quizList.filter((q) =>
        keys.some((key) => containsString(q[key], searchBy))
      );
    }
    setQuizList(quizList);
  }, [initQuizList, searchBy]);

  return (
    <div
      className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ${wrapperClass}`}
    >
      <Container title={title}>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <TextInput
            name="searchBy"
            value={searchBy}
            icon={<FaSearch />}
            onChange={handleChange}
            placeholder="Search By"
          />
        </div>
        <QuizGrid
          quizList={quizList}
          isDisabled={isDisabled}
          isTestQuiz={isTestQuiz}
          isQuizMaster
        />
      </Container>
    </div>
  );
};

export default () => {
  const dispatch = useDispatch();
  const quizList = useSelector((state) => state.quiz.quizList);

  useEffect(() => {
    dispatch(getPredefinedQuizzes(null));
  }, []);

  return (
    <div className="row">
      <GridContainer
        title="My Games"
        quizList={quizList?.filter((c) => !c.isPredefined)}
        wrapperClass="borderRight"
      />
      <GridContainer
        title="Pre-defined Games"
        quizList={quizList?.filter((c) => c.isPredefined)}
        isDisabled
      />
    </div>
  );
};
