import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportView } from "actions/report";
import useUserDetail from "hooks/useUserDetail";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "utils/routeConstant";
import { Container } from "components/Card";
import { FiArrowRightCircle } from "react-icons/fi";

const Card = ({ count, title, url, wrapperClass }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(url);
  };

  return (
    <div
      className={`dashboard-stat ${wrapperClass}`}
      style={{ position: "relative" }}
    >
      <div className="visual">
        <i className="fa fa-user"></i>
      </div>
      <div className="details">
        <div className="number">{count}</div>
        <div className="desc">{title}</div>
      </div>
      <a className="more" href="#" onClick={handleClick}>
        View more <FiArrowRightCircle className="m-icon-white" />
      </a>
    </div>
  );
};

const ReportScreen = () => {
  const dispatch = useDispatch();
  const { isAdmin } = useUserDetail();
  const { quizMasterCount, participantCount } = useSelector(
    (state) => state.report
  );

  useEffect(() => {
    dispatch(reportView());
  }, []);

  return (
    <Container title="Dashboard">
      <div className="row">
        {isAdmin && (
          <div className="col-6">
            <Card
              title="Quiz Masters"
              count={quizMasterCount}
              url={RouteConstant.QUIZ_MASTER_LIST}
              wrapperClass="blue-madison"
            />
          </div>
        )}
        <div className="col-6">
          <Card
            title="Participants"
            count={participantCount}
            url={RouteConstant.PARTICIPANT_LIST}
            wrapperClass="red-intense"
          />
        </div>
        <div className={isAdmin ? "col-6 mt-2" : "col-6"}>
          <Card
            title="Quiz & Participants"
            url={RouteConstant.QUIZ_PARTICIPANT_DETAILS}
            wrapperClass="green-haze"
          />
        </div>
      </div>
    </Container>
  );
};

export default ReportScreen;
