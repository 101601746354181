const { UserButton } = require("components/Button");

export const GridButton = ({ handleClick, icon, text, rowId, className }) => {
  const onClick = (event) => {
    if (event) {
      event.stopPropagation();
    }
    handleClick(rowId);
  };

  return (
    <UserButton
      icon={icon}
      text={text}
      onClick={onClick}
      className={className ? className : "btn"}
    />
  );
};
