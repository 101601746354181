import React from "react";
import { UserLabel } from "../Label";

const Input = (props) => {
  const { name, disabled, onChange, checked, error, id } = props;

  return (
    <>
      <input
        id={id}
        type="radio"
        checked={checked}
        name={`${name}`}
        onChange={onChange}
        className={`md-radiobtn${error ? " errInput" : ""}`}
        disabled={disabled}
      />
    </>
  );
};

const UserRadioButton = (props) => {
  const onFieldChange = (event) => {
    const { onChange, value, name, disabled } = props;
    if (!disabled) onChange(name, value);
  };

  const { errors, label, name, id } = props;
  const error = errors ? errors[name] : null;

  return (
    <>
      <Input {...props} onChange={onFieldChange} error={error} />
      <label htmlFor={id}>{label}</label>
    </>
  );
};

export default UserRadioButton;
