import APIUtil from "api/APIUtil";
import { camelCase, startCase, upperFirst, uniqBy, orderBy } from "lodash";
import moment from "moment";
import { AppConstant } from "utils/constant.js";

export const getMomentDate = (date) => {
  return moment(date, AppConstant.API_DATE_FORMAT);
};

export const formatDisplayDateTime = (date) => {
  if (date)
    return moment(date, AppConstant.API_DATE_FORMAT).format(
      `${AppConstant.DATE_TIME_DISPLAY_FORMAT}`
    );

  return "";
};

export const formatDisplayDate = (date) => {
  if (date)
    return moment(date, AppConstant.API_DATE_FORMAT).format(
      `${AppConstant.DATE_DISPLAY_FORMAT}`
    );

  return "";
};

export const formatTime = (date) => {
  if (date)
    return moment(date, AppConstant.API_DATE_FORMAT).format(
      `${AppConstant.TIME_DISPLAY_FORMAT}`
    );

  return "";
};

export const formatPrice = (price, currency) =>
  `${currency} ${price ? price.toFixed(2) : 0}`;

export const sortList = (list, key, isDesc) => {
  return orderBy(list, [(c) => !c[key], (c) => c[key]], ["asc", "desc"]);
};

export const mapJSONData = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => mapJSONData(v));
  } else if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: mapJSONData(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export const compareString = (str1, str2) => {
  return (
    str1?.replace(" ", "").toLowerCase() === str2.replace(" ", "").toLowerCase()
  );
};

export const containsString = (str, searchBy) => {
  return str
    ?.toString()
    ?.replace(" ", "")
    .toLowerCase()
    .includes(searchBy?.replace(" ", "").toLowerCase());
};

export const formatText = (text) => {
  return startCase(camelCase(text));
};

export const formatInputText = (text) => {
  return text?.split(" ").map(upperFirst).join(" ");
};

export const downloadFile = (base64String, fileName) => {
  const url = window.URL.createObjectURL(converToBlob(base64String));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const downloadFileFromBlob = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const downloadFileFromUrl = (filePath, filename) => {
  const link = document.createElement("a");
  link.href = `${APIUtil.getBaseUrl()}${filePath}`;
  link.download = filename;
  link.click();
};

const converToBlob = (base64str) => {
  var binary = atob(base64str.replace(/\s/g, ""));
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([view], { type: "application/pdf" });
};

const appendFormData = (formData, data, rootName) => {
  let root = rootName || "";
  if (data instanceof File) {
    formData.append(root, data);
  } else if (Array.isArray(data)) {
    for (var i = 0; i < data.length; i++) {
      appendFormData(formData, data[i], root + "[" + i + "]");
    }
  } else if (typeof data === "object" && data) {
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if (root === "") {
          appendFormData(formData, data[key], key);
        } else {
          appendFormData(formData, data[key], root + "." + key);
        }
      }
    }
  } else {
    if (data !== null && typeof data !== "undefined") {
      formData.append(root, data);
    }
  }
};

export const getFormData = (data) => {
  const formData = new FormData();
  appendFormData(formData, data);

  return formData;
};

export const getUniqueList = (list, key) => {
  return list ? uniqBy(list, key) : null;
};
