import {
  getSubscriptionFeatures,
  upgradeSubscription,
} from "actions/subscription";
import { UserButton } from "components/Button";
import useUserDetail from "hooks/useUserDetail";
import React, { useEffect } from "react";
import { BiMenu } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import useToggle from "hooks/useToggle";
import { UserModal } from "components/Modal";
import { AiOutlineLogout } from "react-icons/ai";
import { signOut } from "actions/account";
import useScreenSize from "hooks/useScreenSize";

export default ({ onClick }) => {
  const dispatch = useDispatch();
  const { showUpgrade, displayName, isParticipant } = useUserDetail();
  const { isLoggedIn } = useSelector((state) => state.account);
  const { featureList } = useSelector((state) => state.subscription);
  const { show: showConfirmModal, toggle: toggleConfirmModal } = useToggle();
  const { showMenuAtTop, isMobileView } = useScreenSize();

  useEffect(() => {
    if (showConfirmModal) dispatch(getSubscriptionFeatures());
  }, [showConfirmModal]);

  const handleLogout = () => {
    dispatch(signOut());
  };

  const handleUpgrade = () => {
    dispatch(upgradeSubscription());
    toggleConfirmModal();
  };

  if (isParticipant || !isLoggedIn) {
    return (
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <div id="logo_home">
                <h1>
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/roimoi-logo.png`}
                      alt=""
                    />
                  </a>
                </h1>
              </div>
            </div>
            <div className="col-9">
              {isLoggedIn && (
                <div
                  className="pull-right centerAlign"
                  style={{ height: "100%" }}
                >
                  <AiOutlineLogout
                    color="black"
                    fontSize={24}
                    onClick={handleLogout}
                    className="cursor"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    );
  }

  return (
    <>
      <div className="page-header -i navbar navbar-fixed-top">
        <div className="page-header-inner">
          <div className="page-logo">
            <img src={`${process.env.PUBLIC_URL}/roimoi-logo.png`} alt="" />
          </div>
          {showMenuAtTop && (
            <a className="menu-toggler responsive-toggler" href="#">
              <BiMenu onClick={onClick} size={22} color="white" />
            </a>
          )}
          <div className="top-menu">
            <ul className="nav navbar-nav pull-right">
              <li className="dropdown dropdown-user">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/default-avatar.png`}
                  className="profile-thumb"
                />
                <span className="username username-hide-on-mobile">
                  {displayName}
                </span>
              </li>
              {showUpgrade && (
                <li className="dropdown">
                  <UserButton
                    text="Upgrade Subscription"
                    className="btn btn-primary btn-sm"
                    onClick={toggleConfirmModal}
                  />
                </li>
              )}
              <li className="dropdown">
                <AiOutlineLogout
                  color="white"
                  fontSize={24}
                  onClick={handleLogout}
                  className="cursor"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      {isMobileView && <div class="clearfix"></div>}
      {showConfirmModal && (
        <UserModal
          btnText="Yes"
          show={showConfirmModal}
          heading="Upgrade Subscription"
          onClose={toggleConfirmModal}
          handleClick={handleUpgrade}
        >
          <h4>Features:</h4>
          <ul>
            {featureList &&
              featureList.map((c, index) => <li key={`L${index}`}>{c}</li>)}
          </ul>
          <br />
          <p>
            <b>
              You are about to upgrade your subscription.Please click Yes to
              proceed.
            </b>
          </p>
        </UserModal>
      )}
    </>
  );
};
