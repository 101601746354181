import { getQuizParticipantRanks } from "actions/quiz";
import { UserContainer } from "components/Container";
import { sortList } from "libs/functions";
import { AppConstant } from "utils/constant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Rank = ({
  wrapperId,
  nameStyle,
  name,
  score,
  totalScore,
  timeRemainingInSeconds,
}) => {
  return (
    <div className={`col-lg-4 ${wrapperId}`}>
      <div className="item">
        <label>
          <strong style={{ backgroundColor: "#fff", ...nameStyle }}>
            {name}
          </strong>
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/${wrapperId}.png`}
            alt=""
          />
          <p>
            <span className="score">
              <strong>{totalScore}</strong>
            </span>
          </p>
          <h6 className="wizard-header">
            Score:{" "}
            <strong style={{ display: "inline", verticalAlign: "middle" }}>
              {score}
            </strong>
          </h6>
          <h6>
            Time Saved:{" "}
            <strong style={{ display: "inline", verticalAlign: "middle" }}>
              {timeRemainingInSeconds}
            </strong>
          </h6>
        </label>
      </div>
    </div>
  );
};

const QuizResultScreen = (props) => {
  const dispatch = useDispatch();
  const { quizHostedId } = useParams();
  const { participantRankList } = useSelector((state) => state.quiz);
  const [rankList, setRankList] = useState([]);

  useEffect(() => {
    dispatch(getQuizParticipantRanks(quizHostedId));
  }, [quizHostedId]);

  useEffect(() => {
    if (participantRankList) {
      let rankList = sortList(participantRankList, "totalScore", true);
      if (rankList.length > 3) {
        rankList = rankList.splice(0, 3);
      }
      setRankList(rankList);
    }
  }, [participantRankList]);

  return (
    <UserContainer hideMain hideHeader wrapperClass="podiumWrapper">
      <div style={{ paddingBottom: 10 }} className="plain">
        <div className="container">
          <h1
            style={{
              fontSize: 72,
              textAlign: "center",
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <strong style={{ color: "#0171BD" }}>{AppConstant.APP_NAME}</strong>{" "}
            Leaderboard
          </h1>
          <div
            id="wizard_container"
            style={{ minHeight: 200 }}
            className="wizard"
            novalidate="novalidate"
          >
            <div id="middlee-wizard" className="wizard-branch wizard-wrapper">
              <div className="step wizard-step current">
                <div className="row" style={{ paddingTop: 60 }}>
                  <Rank
                    wrapperId="two"
                    nameStyle={{ color: "#F6B933", fontSize: 36 }}
                    {...rankList?.[1]}
                  />
                  <Rank
                    wrapperId="one"
                    nameStyle={{ color: "#473181", fontSize: 40 }}
                    {...rankList?.[0]}
                  />
                  <Rank
                    wrapperId="three"
                    nameStyle={{ color: "#E6E7E8", fontSize: 36 }}
                    {...rankList?.[2]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserContainer>
  );
};

export default QuizResultScreen;
