export const AppConstant = {
  DATE_FORMAT: "dd/MM/yyyy",
  API_DATE_FORMAT: "YYYY-MM-DD hh:mm:ss",
  DATE_TIME_DISPLAY_FORMAT: "DD/MM/yyyy hh:mm A",
  DATE_DISPLAY_FORMAT: "DD/MM/yyyy",
  TIME_DISPLAY_FORMAT: "hh:mm A",
  USER_KEY: "auth-token",
  FIRST_ITEM_TEXT: "Please select",
  UPLOAD_DOCUMENT_SIZE_LIMIT: 100, //In MB
  CURRENCY: "Rs.",

  //Quiz Status
  QUIZ_STATUS_STARTED: "Started",
  QUIZ_STATUS_COMPLETED: "Completed",

  //Roles
  ADMIN: "Administrator",
  USER: "User",
  PARTICIPANT: "Participant",

  QUIZ_INIT_TIME: 5, //In Seconds,

  APP_NAME: "GEMSOUL",
};
