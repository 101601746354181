import { Account } from "actionTypes/account";

export const loginUser = (payload) => ({
  type: Account.AUTH_USER,
  payload,
});

export const signUpParticipant = (payload) => ({
  type: Account.SIGN_UP_PARTICIPANT,
  payload,
});

export const signUpUser = (payload, cb) => ({
  type: Account.SIGN_UP_USER,
  payload,
  cb,
});

export const sendNewUserOtp = (payload) => ({
  type: Account.SEND_NEW_USER_OTP,
  payload,
});

export const validateUser = (payload, cb) => ({
  type: Account.VALIDATE_USER,
  payload,
  cb,
});

export const signOut = (payload) => ({
  type: Account.SIGN_OUT,
  payload,
});

export const forgotPassword = (payload) => ({
  type: Account.FORGOT_PASSWORD,
  payload,
});

export const resetPassword = (payload, cb) => ({
  type: Account.RESET_PASSWORD,
  payload,
  cb,
});
