import { QRCodeSVG } from "qrcode.react";

const UserQrCode = ({ value }) => {
  return (
    <>
      <QRCodeSVG value={value} />
    </>
  );
};

export default UserQrCode;
