import { Quiz } from "actionTypes/quiz";
import { Subscription } from "actionTypes/subscription";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Quiz.ADD_QUIZ_VIEW:
    case Subscription.SUBSCRIPTION_VIEW:
      return {
        ...initialState,
      };
    case Subscription.SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
    default:
      return { ...state };
  }
};
