import { Quiz } from "actionTypes/quiz";

export const addQuizView = (payload) => ({
  type: Quiz.ADD_QUIZ_VIEW,
  payload,
});

export const getPredefinedQuizzes = (payload) => ({
  type: Quiz.GET_PREDEFINED_QUIZZES,
  payload,
});

export const getQuizList = (payload) => ({
  type: Quiz.GET_QUIZ_LIST,
  payload,
});

export const addEditPredefinedQuiz = (payload, cb) => ({
  type: Quiz.ADD_EDIT_PREDEFINED_QUIZ,
  payload,
  cb,
});

export const uploadQuestionFile = (payload) => ({
  type: Quiz.UPLOAD_QUESTION_FILE,
  payload,
});

export const getPredefinedQuiDetail = (payload) => ({
  type: Quiz.GET_PREDEFINED_QUIZ_DETAILS,
  payload,
});

export const hostQuiz = (payload, cb) => ({
  type: Quiz.HOST_QUIZ,
  payload,
  cb,
});

export const getHostedQuizDetail = (payload) => ({
  type: Quiz.GET_HOSTED_QUIZ_DETAIL,
  payload,
});

export const getQuizParticipants = (payload) => ({
  type: Quiz.GET_QUIZ_PARTICIPANTS,
  payload,
});

export const getQuizParticipantRanks = (payload) => ({
  type: Quiz.GET_QUIZ_PARTICIPANT_RANKS,
  payload,
});

export const validateQuizCode = (payload, cb) => ({
  type: Quiz.VALIDATE_QUIZ_CODE,
  payload,
  cb,
});

export const startQuiz = (payload) => ({
  type: Quiz.START_QUIZ,
  payload,
});

export const finishQuiz = (payload) => ({
  type: Quiz.FINISH_QUIZ,
  payload,
});

export const setQuiz = (result) => ({
  type: Quiz.QUIZ_SUCCESS,
  result,
});

export const getAllQuizzes = () => ({
  type: Quiz.GET_ALL_QUIZZES,
});
