import { User } from "actionTypes/user";

export const getQuizQuestion = (payload, cb) => ({
  type: User.GET_QUIZ_QUESTION,
  payload,
  cb,
});

export const addEditUserQuestion = (payload, cb) => ({
  type: User.ADD_EDIT_QUIZ_QUESTION,
  payload,
  cb,
});

export const sendOTP = (payload, cb) => ({
  type: User.SEND_OTP,
  payload,
  cb,
});

export const validateOTP = (payload, cb) => ({
  type: User.VALIDATE_OTP,
  payload,
  cb,
});
