import { useSelector } from "react-redux";
import { AppConstant } from "utils/constant";

const useUserDetail = () => {
  const { userData } = useSelector((state) => state.account);

  return {
    displayName: userData?.displayName,
    email: userData?.emailAddress,
    isAdmin: userData?.role == AppConstant.ADMIN,
    isUser: userData?.role == AppConstant.USER,
    isParticipant: userData?.role == AppConstant.PARTICIPANT,
    role: userData?.role,
    subscriptionTypeId: userData?.subscriptionTypeId,
    showUpgrade: userData?.showUpgrade && userData?.role == AppConstant.USER,
  };
};

export default useUserDetail;
