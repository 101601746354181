import React from "react";
import { LeftMenu } from "components/Menu";
import useSignalR from "hooks/useSignalR";
import { UserHeader } from "components/Header";
import { useSelector } from "react-redux";
import Routes from "config/route";
import useToggle from "hooks/useToggle";
import useScreenSize from "hooks/useScreenSize";
import { useLocation } from "react-router-dom";
import { RouteConstant } from "utils/routeConstant";

export default (props) => {
  const { isLoggedIn } = useSelector((state) => state.account);
  useSignalR(isLoggedIn);
  const { showMenuAtTop } = useScreenSize();
  const { show: showMenu, toggle: toggleMenu } = useToggle(true);
  const { pathname } = useLocation();
  const path = pathname?.slice(0, pathname?.lastIndexOf("/"));
  const hideMenu =
    path == RouteConstant.QUIZ_PARTICIPANTS ||
    path == RouteConstant.QUIZ_PARTICIPANT_RANK ||
    path == RouteConstant.USER_QUIZ_QUESTIONS;

  const hideHeader = path == RouteConstant.USER_QUIZ_QUESTIONS;

  if (isLoggedIn)
    return (
      <>
        {!hideHeader && <UserHeader onClick={toggleMenu} />}
        <div
          className={`page-container ${
            !showMenu && !showMenuAtTop ? "page-sidebar-closed" : ""
          } ${showMenuAtTop && !showMenu ? "toggled" : ""}`}
        >
          {!hideMenu ? (
            <>
              <LeftMenu onClick={toggleMenu} showMenu={showMenu} />
              <div className="page-content-wrapper">
                <div className="page-content">
                  <Routes />
                </div>
              </div>
            </>
          ) : (
            <div className="page-content-wrapper">
              <Routes />
            </div>
          )}
        </div>
      </>
    );

  return <Routes />;
};
