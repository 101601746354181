import React from "react";
import { TextInput } from ".";

export default (props) => {
  const {
    onChange,
    multiple,
    fileSrcName,
    isExcel,
    files,
    showFileName,
    label,
  } = props;

  return (
    <div className="fileInput">
      <TextInput
        className="custom-file-input"
        name={fileSrcName ? fileSrcName : "file"}
        type="file"
        id="file"
        value=""
        placeholder=""
        onChange={onChange}
        multiple={multiple}
        isExcel={isExcel}
      />
      {showFileName && (
        <>
          {multiple && files ? (
            <div className="col-6">
              <ul>
                {files.map((c, index) => (
                  <li key={`F${index}`}>
                    <b>{c.fileName}</b>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <>
              <b>{files?.fileName}</b>
            </>
          )}
        </>
      )}
    </div>
  );
};
