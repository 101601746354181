import { UserTable } from "components/Table";
import { formatDisplayDate } from "libs/functions";
import { useCallback } from "react";
import { RouteConstant } from "utils/routeConstant";
import { ExportCSV } from "components/Document";
import HostQuiz from "./HostQuiz";
import UserLink from "components/Link/UserLink";

const headers = [
  {
    key: "displayName",
    text: "Display Name",
  },
  {
    key: "subscriptionType",
    text: "Accessible To",
  },
  {
    key: "createdOn",
    text: "Created On",
  },
  { key: "edit", text: " " },
];

const quizMasterHeaders = [
  ...headers.filter((c) => c.key != "subscriptionType"),
  { key: "action", text: " " },
];

const quizMasterPredefinedHeaders = quizMasterHeaders.filter(
  (c) => c.key != "edit"
);

const rowId = "quizId";

const QuizGrid = ({ isDisabled, quizList, isQuizMaster, isTestQuiz }) => {
  const rows = useCallback(() => {
    if (quizList) {
      return quizList.map((c) => ({
        ...c,
        action: <HostQuiz quizId={c[rowId]} isTestQuiz={isTestQuiz} />,
        edit: isDisabled ? (
          <></>
        ) : (
          <div className="centerAlign">
            <UserLink
              href={
                isQuizMaster
                  ? `${RouteConstant.ADD__QUIZ}/${c[rowId]}`
                  : `${RouteConstant.ADD_PREDEFINED_QUIZ}/${c[rowId]}`
              }
              text="Edit"
              className="btn btn-primary btn-sm"
            />
          </div>
        ),
        createdOn: formatDisplayDate(c.createdOn),
      }));
    }

    return [];
  }, [quizList]);

  const tblHeaders = isQuizMaster
    ? isDisabled
      ? quizMasterPredefinedHeaders
      : quizMasterHeaders
    : headers;
  const tblRows = rows();

  return (
    <>
      {!isTestQuiz && (
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div className={isQuizMaster ? "pull-right" : ""}>
            <ExportCSV
              btnText="Download"
              filename="Game_List.csv"
              headers={tblHeaders
                .filter((c) => c.key != "action" && c.key != "edit")
                .map((c) => ({ ...c, label: c.text }))}
              data={tblRows}
            />
          </div>
        </div>
      )}
      <div className={`quizTable mt-2 ${!isDisabled ? "active" : ""}`}>
        <UserTable headers={tblHeaders} data={tblRows} rowId={rowId} />
      </div>
    </>
  );
};

export default QuizGrid;
