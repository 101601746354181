import { UserButton } from "components/Button";
import { UserCheckbox } from "components/Checkbox";
import { UserSelect } from "components/SelectBox";
import TextInput from "components/TextInput/TextInput";
import useForm from "hooks/useForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  subscriptionView,
  getSubscriptionDetails,
  addEditSubscription,
} from "actions/subscription";
import { Container } from "components/Card";

const AddSubscriptionScreen = (props) => {
  const dispatch = useDispatch();
  const { subscriptionTypes, subscriptionDetail } = useSelector(
    (state) => state.subscription
  );
  const {
    state,
    handleChange,
    handleStateChange,
    setState,
    errors,
    handleSubmit,
  } = useForm({}, {}, handleSave);
  const {
    subscriptionTypeId,
    defaultTimePerQuestion,
    defaultScorePerQuestion,
    maxNumberOfGamesInDay,
    maxNumberOfQuestionsInGame,
    maxNumberOfParticipantsInGame,
    sendReportToParticipants,
    randomQuestions,
    allowMultipleLogins,
    accessibilityToPredefinedGames,
    sendReportToQuizMaster,
    allowQuizDownload,
    allowQuestionsUpload,
    allowQuizPause,
  } = state;

  useEffect(() => {
    dispatch(subscriptionView());
  }, []);

  useEffect(() => {
    if (subscriptionTypes && subscriptionTypes.length > 0) {
      handleChange("subscriptionTypeId", subscriptionTypes[0].value);
    }
  }, [subscriptionTypes]);

  useEffect(() => {
    if (subscriptionTypeId) {
      dispatch(getSubscriptionDetails(subscriptionTypeId));
    }
  }, [subscriptionTypeId]);

  useEffect(() => {
    setState({ ...subscriptionDetail, subscriptionTypeId });
  }, [subscriptionDetail, subscriptionTypeId]);

  const onTimeChange = (name, value) => {
    handleStateChange({ [name]: value, defaultScorePerQuestion: value });
  };

  function handleSave(payload) {
    dispatch(addEditSubscription(payload));
  }

  return (
    <Container title="Add Subscription">
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserSelect
          name="subscriptionTypeId"
          value={subscriptionTypeId}
          options={subscriptionTypes}
          placeholder="Select Subscription"
          label="Subscription"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <TextInput
          name="defaultTimePerQuestion"
          value={defaultTimePerQuestion}
          type="float"
          maxLength={50}
          label="Default Time (Seconds)"
          onChange={onTimeChange}
          errors={errors}
        />
      </div>
      {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <TextInput
          name="defaultScorePerQuestion"
          value={defaultScorePerQuestion}
          type="float"
          maxLength={50}
          label="Default Score"
          onChange={handleChange}
          errors={errors}
        />
      </div> */}
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <TextInput
          name="maxNumberOfGamesInDay"
          value={maxNumberOfGamesInDay}
          type="number"
          maxLength={50}
          label="Maximum Games"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <TextInput
          name="maxNumberOfQuestionsInGame"
          value={maxNumberOfQuestionsInGame}
          type="number"
          maxLength={50}
          label="Maximum Questions"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <TextInput
          name="maxNumberOfParticipantsInGame"
          value={maxNumberOfParticipantsInGame}
          maxLength={50}
          type="number"
          label="Maximum Participants"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserCheckbox
          name="sendReportToParticipants"
          value={sendReportToParticipants}
          onChange={handleChange}
          label="Send Reports to Participants"
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserCheckbox
          name="randomQuestions"
          value={randomQuestions}
          onChange={handleChange}
          label="Random Questions"
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserCheckbox
          name="allowMultipleLogins"
          value={allowMultipleLogins}
          onChange={handleChange}
          label="Allow Multiple Logins"
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserCheckbox
          name="accessibilityToPredefinedGames"
          value={accessibilityToPredefinedGames}
          onChange={handleChange}
          label="Accessibility to Predefined Games"
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserCheckbox
          name="sendReportToQuizMaster"
          value={sendReportToQuizMaster}
          onChange={handleChange}
          label="Send Reports to Users"
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserCheckbox
          name="allowQuestionsUpload"
          value={allowQuestionsUpload}
          onChange={handleChange}
          label="Allow Quiz Upload"
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserCheckbox
          name="allowQuizDownload"
          value={allowQuizDownload}
          onChange={handleChange}
          label="Allow Quiz Download"
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserCheckbox
          name="allowQuizPause"
          value={allowQuizPause}
          onChange={handleChange}
          label="Allow Quiz Pause"
        />
      </div>
      <div className="col-12">
        <UserButton text="Save" onClick={handleSubmit} />
      </div>
    </Container>
  );
};

export default AddSubscriptionScreen;
