import React from "react";
import { useDispatch } from "react-redux";
import { TextInput } from "components/TextInput";
import useForm from "hooks/useForm";
import { resetPassword } from "actions/account";
import { UserButton } from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import UserLink from "components/Link/UserLink";
import { RouteConstant } from "utils/routeConstant";
import useToast from "hooks/useToast";
import { LoginWrapper } from "./Components";

const validationSchema = {
  email: { required: true },
  otp: { required: true },
  password: {
    required: true,
  },
  confirmPassword: {
    required: true,
  },
};

export default (props) => {
  const dispatch = useDispatch();
  const { emailAddress: userEmail } = useParams();
  const navigate = useNavigate();
  const { state, handleChange, errors, handleSubmit } = useForm(
    { email: userEmail },
    validationSchema,
    submit
  );
  const { showError } = useToast();

  const { email, otp, password, confirmPassword } = state;

  function submit(payload) {
    if (payload.password != payload.confirmPassword) {
      showError("New password and confirm password must be same");
      return;
    }
    dispatch(
      resetPassword({ ...payload }, () => {
        navigate(RouteConstant.LOGIN);
      })
    );
  }

  return (
    <LoginWrapper title="Reset Password">
      <div className="form-group">
        <TextInput
          name="email"
          id="email"
          value={email}
          asterix
          placeholder="Enter Email"
          onChange={handleChange}
          errors={errors}
          disabled
        />
      </div>
      <div className="form-group">
        <TextInput
          name="otp"
          value={otp}
          asterix
          placeholder="Enter OTP"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="form-group">
        <TextInput
          name="password"
          id="password"
          type="password"
          value={password}
          asterix
          placeholder="Enter new password"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="form-group">
        <TextInput
          name="confirmPassword"
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          asterix
          placeholder="Confirm password"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="form-actions">
        <UserButton
          type="submit"
          text="Reset Password"
          className="btn green-haze pull-right"
          onClick={handleSubmit}
        />
      </div>
      <div className="create-account">
        <p>
          Back to&nbsp;&nbsp;
          <UserLink href={RouteConstant.LOGIN} text="Login" className=" " />
        </p>
      </div>
    </LoginWrapper>
  );
};
