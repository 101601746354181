import React from "react";

const UserButton = ({
  text,
  type,
  className,
  disabled = false,
  onClick,
  icon,
  showIconFirst,
  value,
  title,
}) => {
  let btnText = showIconFirst ? (
    <>
      {icon ? icon : ""} <span className={`btext`}>{text}</span>
    </>
  ) : (
    <>
      <span className={`btext`}>{text}</span> {icon ? icon : ""}
    </>
  );
  return (
    <button
      type={type ? type : "button"}
      disabled={disabled}
      title={title}
      value={value}
      className={className ? className : "btn btn-primary btn-sm"}
      onClick={onClick}
    >
      {btnText}
    </button>
  );
};

export default UserButton;
