import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextInput } from "components/TextInput";
import { UserButton } from "components/Button";
import useForm from "hooks/useForm";
import { RouteConstant } from "utils/routeConstant";
import { useNavigate } from "react-router-dom";
import { validateQuizCode } from "actions/quiz";
import { Container } from "components/Card";
import UserLink from "components/Link/UserLink";
import { UserContainer } from "components/Container";
import useDefaultPage from "hooks/useDefaultPage";
import { AppConstant } from "utils/constant";

const QuizCode = ({ isLoggedIn }) => {
  const dispatch = useDispatch();
  const { state, errors, handleChange, handleSubmit } = useForm(
    {},
    { code: { required: true } },
    handleVerify
  );
  useDefaultPage();
  const navigate = useNavigate();
  const { code } = state;

  function handleVerify(payload) {
    dispatch(
      validateQuizCode(payload.code, ({ quizHostedId }) => {
        if (isLoggedIn) {
          navigate(`${RouteConstant.USER_QUIZ_QUESTIONS}/${quizHostedId}`);
        } else {
          navigate(`${RouteConstant.SIGN_UP_PARTICIPANT}/${quizHostedId}`);
        }
      })
    );
  }

  return (
    <>
      <div className="col-lg-6">
        <div className="item">
          <label labelfor="code">
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/seo_icon_1.svg`}
              alt=""
            />
            <strong>Enter Code to Play Quiz!</strong>
            <p>
              <TextInput
                name="code"
                value={code}
                maxLength={6}
                asterix
                placeholder="Enter the Code shared by Quiz Master"
                onChange={handleChange}
                errors={errors}
              />
            </p>
            <UserButton
              type="submit"
              text="Proceed to Play"
              icon={<span className="icon">{">"}</span>}
              className="forward"
              onClick={handleSubmit}
            />
          </label>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="item">
          <label labelfor="answer_2">
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/web_development_icon_1.svg`}
              alt=""
            />
            <strong>Quizmaster's Login</strong>
            <p>
              <UserLink
                href={RouteConstant.LOGIN}
                text="Login to your Panel"
                className="btn forward"
              />
            </p>
            <p style={{ marginBottom: 23 }}>
              New to {AppConstant.APP_NAME}&nbsp;
              <UserLink
                href={RouteConstant.SIGN_UP}
                text="Introductory Offer! Become ROIMOI Quizmaster for Free! Offer valid till 15th August, 2023"
                className=" "
              />
            </p>
          </label>
        </div>
      </div>
    </>
  );
};

const QuizCodeScreen = () => {
  const { isLoggedIn } = useSelector((state) => state.account);

  if (isLoggedIn) {
    return (
      <Container>
        <div className="col-12">
          <QuizCode isLoggedIn />
        </div>
      </Container>
    );
  }

  return (
    <UserContainer title="Welcome">
      <QuizCode />
    </UserContainer>
  );
};

export default QuizCodeScreen;
