import { Chart as ChartJS, registerables } from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(...registerables);

const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "left",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

const BarChart = ({ data, label, minValue, maxValue }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    if (minValue || maxValue) {
      let chartOptions = options;
      chartOptions.scales.x.min = minValue;
      chartOptions.scales.x.max = maxValue;
      setChartOptions(chartOptions);
    }
  }, [minValue, maxValue]);

  useEffect(() => {
    if (data) {
      const chartData = {
        labels: data.map((c) => c.label),
        datasets: [
          {
            label: label,
            data: data.map((c) => c.xData),
            backgroundColor: data.map((c) => c.colourCode),
          },
        ],
      };
      setChartData(chartData);
    }
  }, [data, label]);

  return (
    <>
      {chartOptions && (
        <div
          className="chartContainer"
          style={{ height: data?.length * 30 + 40 }}
        >
          <Bar
            options={chartOptions}
            data={chartData}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      )}
    </>
  );
};

export default BarChart;
