import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { RouteConstant } from "utils/routeConstant.js";
import auth from "libs/auth.js";
import {
  LoginScreen,
  QuizCodeScreen,
  SignUpParticipantScreen,
  SignUpScreen,
  ValidateUserScreen,
  ForgotPasswordScreen,
  ResetPasswordScreen,
} from "containers/Login";
import { AddSubscriptionScreen } from "containers/Subscription";
import {
  PredefinedQuizScreen,
  AddPredefinedQuizScreen,
  AddQuizScreen,
  QuizScreen,
  TestQuizScreen,
} from "containers/Quiz";
import useUserDetail from "hooks/useUserDetail";
import {
  HostQuizScreen,
  ParticipantScreen,
  ParticipantQuestionScreen,
  ParticipantRankScreen,
  TestParticipantScreen,
  QuizResultScreen,
} from "containers/HostQuiz";
import { AppConstant } from "utils/constant";
import {
  ParticipantListScreen,
  QuizMasterListScreen,
  QuizParticipantDetailScreen,
  ReportScreen,
} from "containers/Report";

const {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGN_UP_PARTICIPANT,
  SIGN_UP,
  VERIFY_ACCOUNT,
  QUIZ_CODE,
  USER_QUIZ_CODE,
  SUBSCRIPTION,
  PREDEFINED_QUIZZES,
  ADD_PREDEFINED_QUIZ,
  QUIZZES,
  ADD__QUIZ,
  HOST_QUIZ,
  QUIZ_PARTICIPANTS,
  TEST_QUIZZES,
  TEST_QUIZ_PARTICIPANTS,
  QUIZ_PARTICIPANT_RANK,
  QUIZ_PARTICIPANT_RESULT,
  USER_QUIZ_QUESTIONS,
  REPORT,
  QUIZ_MASTER_LIST,
  PARTICIPANT_LIST,
  QUIZ_PARTICIPANT_DETAILS,
} = RouteConstant;

const PrivateRoute = ({ authorized }) => {
  const { role } = useUserDetail();
  if (auth.isAuthenticated && (!authorized || authorized.includes(role)))
    return (
      <>
        <Outlet />
      </>
    );

  return <Navigate to={QUIZ_CODE} />;
};

export default (props) => {
  return (
    <Routes>
      <Route element={<PrivateRoute authorized={[AppConstant.ADMIN]} />}>
        <Route
          path={`${ADD_PREDEFINED_QUIZ}/:quizId?`}
          element={<AddPredefinedQuizScreen />}
        />
        <Route path={PREDEFINED_QUIZZES} element={<PredefinedQuizScreen />} />
        <Route path={SUBSCRIPTION} element={<AddSubscriptionScreen />} />
        <Route path={QUIZ_MASTER_LIST} element={<QuizMasterListScreen />} />
        <Route path={`${TEST_QUIZZES}`} element={<TestQuizScreen />} />
        <Route
          path={`${TEST_QUIZ_PARTICIPANTS}/:quizHostedId`}
          element={<TestParticipantScreen />}
        />
      </Route>
      <Route element={<PrivateRoute authorized={[AppConstant.USER]} />}>
        <Route path={`${ADD__QUIZ}/:quizId?`} element={<AddQuizScreen />} />
        <Route path={QUIZZES} element={<QuizScreen />} />
      </Route>
      <Route
        element={
          <PrivateRoute authorized={[AppConstant.ADMIN, AppConstant.USER]} />
        }
      >
        <Route path={HOST_QUIZ} element={<HostQuizScreen />} />
        <Route
          path={`${QUIZ_PARTICIPANTS}/:quizHostedId`}
          element={<ParticipantScreen />}
        />
        <Route
          path={`${QUIZ_PARTICIPANT_RANK}/:quizHostedId`}
          element={<ParticipantRankScreen />}
        />
        <Route
          path={`${QUIZ_PARTICIPANT_RESULT}/:quizHostedId`}
          element={<QuizResultScreen />}
        />
        <Route path={REPORT} element={<ReportScreen />} />
        <Route path={PARTICIPANT_LIST} element={<ParticipantListScreen />} />
        <Route
          path={QUIZ_PARTICIPANT_DETAILS}
          element={<QuizParticipantDetailScreen />}
        />
      </Route>
      <Route element={<PrivateRoute authorized={[AppConstant.PARTICIPANT]} />}>
        <Route
          path={`${USER_QUIZ_QUESTIONS}/:quizHostedId`}
          element={<ParticipantQuestionScreen />}
        />
        <Route path={USER_QUIZ_CODE} element={<QuizCodeScreen />} />
      </Route>
      <Route
        path={`testQuiz/:quizHostedId`}
        element={<ParticipantQuestionScreen />}
      />
      <Route
        path={`${SIGN_UP_PARTICIPANT}/:quizHostedId`}
        element={<SignUpParticipantScreen />}
      />
      <Route path={`${SIGN_UP}`} element={<SignUpScreen />} />
      <Route
        path={`${VERIFY_ACCOUNT}/:emailAddress`}
        element={<ValidateUserScreen />}
      />
      <Route
        path={`${RESET_PASSWORD}/:emailAddress`}
        element={<ResetPasswordScreen />}
      />
      <Route path={FORGOT_PASSWORD} element={<ForgotPasswordScreen />} />
      <Route path={LOGIN} element={<LoginScreen />} />
      <Route path={QUIZ_CODE} element={<QuizCodeScreen />} />
      <Route path="/" element={<QuizCodeScreen />} />
    </Routes>
  );
};
