export default ({ title, children }) => {
  return (
    <div className="card h-100">
      <div className="card-body">
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <h3 className="page-title">{title}</h3>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
