import { getQuizList, hostQuiz } from "actions/quiz";
import { UserButton } from "components/Button";
import { Container } from "components/Card";
import { UserSelect } from "components/SelectBox";
import useForm from "hooks/useForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "utils/routeConstant";

const HostQuizScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quizSelectList } = useSelector((state) => state.quiz);
  const { state, errors, handleChange, handleSubmit } = useForm(
    {},
    { quizId: { required: true } },
    handleSave
  );
  const { quizId } = state;

  useEffect(() => {
    dispatch(getQuizList());
  }, []);

  function handleSave(payload) {
    dispatch(
      hostQuiz(payload.quizId, ({ quizHostedId }) => {
        navigate(`${RouteConstant.QUIZ_PARTICIPANTS}/${quizHostedId}`);
      })
    );
  }

  return (
    <Container title="Host Game">
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserSelect
          name="quizId"
          value={quizId}
          options={quizSelectList}
          placeholder="Select Game"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <UserButton text="Generate Code" onClick={handleSubmit} />
      </div>
    </Container>
  );
};

export default HostQuizScreen;
