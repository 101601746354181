import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuizzes } from "actions/quiz";
import { GridContainer } from "./QuizScreen";

const TestQuizScreen = () => {
  const dispatch = useDispatch();
  const { quizList } = useSelector((state) => state.quiz);

  useEffect(() => {
    dispatch(getAllQuizzes());
  }, []);

  return (
    <GridContainer title="Games" quizList={quizList} isTestQuiz isDisabled />
  );
};

export default TestQuizScreen;
