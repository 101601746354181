import { hostQuiz } from "actions/quiz";
import { useDispatch } from "react-redux";
import { GridButton } from "components/Table/TableActions";
import useToggle from "hooks/useToggle";
import { UserModal } from "components/Modal";
import AddQuizDetail from "./AddQuizDetail";
import { RouteConstant } from "utils/routeConstant";

const baseUrl = window.location.origin;

const HostQuiz = ({ quizId, isTestQuiz }) => {
  const dispatch = useDispatch();
  const { show: showModal, toggle: toggleModal } = useToggle();

  const handleHost = (payload) => {
    dispatch(
      hostQuiz({ ...payload, quizId, isTestQuiz }, ({ quizHostedId }) => {
        window.open(
          `${baseUrl}${
            isTestQuiz
              ? RouteConstant.TEST_QUIZ_PARTICIPANTS
              : RouteConstant.QUIZ_PARTICIPANTS
          }/${quizHostedId}`,
          "_blank"
        );
      })
    );
    toggleModal();
  };

  return (
    <>
      <div className="centerAlign">
        <GridButton
          rowId={quizId}
          text="Host"
          handleClick={toggleModal}
          className="btn btn-primary btn-sm"
        />
      </div>
      {showModal && (
        <UserModal
          show={showModal}
          onClose={toggleModal}
          heading="Host Game"
          hideBtn
        >
          <AddQuizDetail
            title=" "
            isQuizMaster
            isHostingQuiz
            quizId={quizId}
            handleSave={handleHost}
          />
        </UserModal>
      )}
    </>
  );
};

export default HostQuiz;
