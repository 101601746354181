import APIUtil from "api/APIUtil";
import UserLink from "components/Link/UserLink";

const baseUrl = APIUtil.getBaseUrl();

const QuizTemplate = () => {
  return (
    <UserLink
      text="Download Template"
      href={`${baseUrl}QuestionMasterFormat.xls`}
      className="btn btn-primary btn-sm"
      target="_blank"
      download
    />
  );
};

export default QuizTemplate;
