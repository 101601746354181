import React from "react";

export default ({ labelClassName, tip, name, id, asterix, label }) => {
  return (
    <>
      <label
        className={labelClassName ? labelClassName : "control-label"}
        htmlFor={id ? id : name}
      >
        {label}
        {asterix ? <span className="asterix">*</span> : ""}
      </label>
      {tip ? <div className="tip">{tip}</div> : ""}
    </>
  );
};
