import { UserHeader } from "components/Header";
import { UserModal } from "components/Modal";
import useToggle from "hooks/useToggle";
import React from "react";
import { AppConstant } from "utils/constant";

const Footer = () => {
  const { show: showTermsModal, toggle: toggleTermsModal } = useToggle();
  const { show: showPrivacyModal, toggle: togglePrivacyModal } = useToggle();
  const { show: showRefundModal, toggle: toggleRefundModal } = useToggle();

  return (
    <>
      <footer>
        <div className="container clearfix">
          <ul>
            <li>
              <a href="#" className="animated_link" onClick={toggleTermsModal}>
                Terms
              </a>
            </li>
            <li>
              <a
                href="#"
                className="animated_link"
                onClick={togglePrivacyModal}
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#" class="animated_link" onClick={toggleRefundModal}>
                Refund Policy
              </a>
            </li>
          </ul>
          <p>© {AppConstant.APP_NAME}</p>
        </div>
      </footer>
      {showTermsModal && (
        <UserModal
          show={showTermsModal}
          onClose={toggleTermsModal}
          heading="Terms and conditions"
          hideBtn
        >
          <>
            <p>
              The Website Owner, including subsidiaries and affiliates
              ("Website" or "Website Owner" or "we" or "us" or "our") provides
              the information contained on the website or any of the pages
              comprising the website ("website") to visitors ("visitors")
              (cumulatively referred to as "you" or "your" hereinafter) subject
              to the terms and conditions set out in these website terms and
              conditions, the privacy policy and any other relevant terms and
              conditions, policies and notices which may be applicable to a
              specific section or module of the website.
            </p>
            <p>
              Welcome to our website. If you continue to browse and use this
              website you are agreeing to comply with and be bound by the
              following terms and conditions of use, which together with our
              privacy policy govern RoiMoi's relationship with you in relation
              to this website.
            </p>
            <p>
              The term 'RoiMoi' or 'us' or 'we' refers to the owner of the
              website whose registered/operational office is No. The term 'you'
              refers to the user or viewer of our website.
            </p>
            <p>
              The use of this website is subject to the following terms of use:
            </p>
            <ul>
              <li>
                The content of the pages of this website is for your general
                information and use only. It is subject to change without
                notice.
              </li>
              <li>
                Neither we nor any third parties provide any warranty or
                guarantee as to the accuracy, timeliness, performance,
                completeness or suitability of the information and materials
                found or offered on this website for any particular purpose. You
                acknowledge that such information and materials may contain
                inaccuracies or errors and we expressly exclude liability for
                any such inaccuracies or errors to the fullest extent permitted
                by law.
              </li>
              <li>
                Your use of any information or materials on this website is
                entirely at your own risk, for which we shall not be liable. It
                shall be your own responsibility to ensure that any products,
                services or information available through this website meet your
                specific requirements.
              </li>
              <li>
                This website contains material which is owned by or licensed to
                us. This material includes, but is not limited to, the design,
                layout, look, appearance and graphics. Reproduction is
                prohibited other than in accordance with the copyright notice,
                which forms part of these terms and conditions.
              </li>
              <li>
                All trademarks reproduced in this website which are not the
                property of, or licensed to, the operator are acknowledged on
                the website.
              </li>
              <li>
                Unauthorized use of this website may give rise to a claim for
                damages and/or be a criminal offense.
                <br />
                From time to time this website may also include links to other
                websites. These links are provided for your convenience to
                provide further information.
              </li>
              <li>
                You may not create a link to this website from another website
                or document without RoiMoi’s prior written consent.
              </li>
              <li>
                Your use of this website and any dispute arising out of such use
                of the website is subject to the laws of India or other
                regulatory authority.
              </li>
            </ul>
            <p>
              We as a merchant shall be under no liability whatsoever in respect
              of any loss or damage arising directly or indirectly out of the
              decline of authorization for any Transaction, on Account of the
              Cardholder having exceeded the preset limit mutually agreed by us
              with our acquiring bank from time to time.
            </p>
            <h3>Cancellation &amp; Refund Policy</h3>
            <p>No cancellations &amp; Refunds are entertained.</p>
          </>
        </UserModal>
      )}
      {showPrivacyModal && (
        <UserModal
          show={showPrivacyModal}
          onClose={togglePrivacyModal}
          heading="Privacy Policy"
          hideBtn
        >
          <>
            <p>
              This privacy policy sets out how RoiMoi uses and protects any
              information that you give RoiMoi when you use this website.
            </p>

            <p>
              <br />
              RoiMoi is committed to ensuring that your privacy is protected.
              Should we ask you to provide certain information by which you can
              be identified when using this website, and then you can be assured
              that it will only be used in accordance with this privacy
              statement.
            </p>

            <p>
              <br />
              RoiMoi may change this policy from time to time by updating this
              page. You should check this page from time to time to ensure that
              you are happy with any changes.
            </p>

            <p>
              <br />
              We may collect the following information:
            </p>

            <ul>
              <li>Name and job title</li>
              <li>Contact information including email address</li>
              <li>
                Demographic information such as postcode, preferences and
                interests
              </li>
              <li>
                Other information relevant to customer surveys and/or offer
              </li>
              <li>Information relevant to our services</li>
            </ul>

            <p>
              What we do with the information we gather?
              <br />
              We require this information to understand your needs and provide
              you with a better service, and in particular for the following
              reasons:
            </p>

            <ul>
              <li>Internal record keeping.</li>
              <li>
                We may use the information to improve our products and services.
              </li>
              <li>
                We may periodically send promotional emails about new products,
                special offers or other information which we think you may find
                interesting using the email address which you have provided.
              </li>
              <li>
                From time to time, we may also use your information to contact
                you for market research purposes. We may contact you by email,
                phone, fax or mail. We may use the information to customise the
                website according to your interests.
              </li>
            </ul>

            <p>
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorised access or disclosure we have put in
              suitable measures.
            </p>

            <h3>
              <br />
              How we use cookies
            </h3>

            <p>
              <br />
              A cookie is a small file which asks permission to be placed on
              your computer's hard drive. Once you agree, the file is added and
              the cookie helps analyses web traffic or lets you know when you
              visit a particular site. Cookies allow web applications to respond
              to you as an individual. The web application can tailor its
              operations to your needs, likes and dislikes by gathering and
              remembering information about your preferences.
              <br />
              We use traffic log cookies to identify which pages are being used.
              This helps us analyses data about webpage traffic and improve our
              website in order to tailor it to customer needs. We only use this
              information for statistical analysis purposes and then the data is
              removed from the system.
              <br />
              Overall, cookies help us provide you with a better website, by
              enabling us to monitor which pages you find useful and which you
              do not. A cookie in no way gives us access to your computer or any
              information about you, other than the data you choose to share
              with us.
              <br />
              You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. This may prevent
              you from taking full advantage of the website.
            </p>

            <h3>
              <br />
              Controlling your personal information
            </h3>

            <p>
              <br />
              You may choose to restrict the collection or use of your personal
              information in the following ways:
            </p>

            <ul>
              <li>
                whenever you are asked to fill in a form on the website, look
                for the box that you can click to indicate that you do not want
                the information to be used by anybody for direct marketing
                purposes
              </li>
              <li>
                if you have previously agreed to us using your personal
                information for direct marketing purposes, you may change your
                mind at any time by writing to or emailing us at{" "}
                <strong>connect@RoiMoi.co.in</strong>
              </li>
            </ul>

            <p>
              We will not sell, distribute or lease your personal information to
              third parties unless we have your permission or are required by
              law to do so. We may use your personal information to send you
              promotional information about third parties which we think you may
              find interesting if you tell us that you wish this to happen.
            </p>

            <p>
              <br />
              If you believe that any information we are holding on you is
              incorrect or incomplete, please write to or email us as soon as
              possible, at the above address. We will promptly correct any
              information found to be incorrect.
            </p>
          </>
        </UserModal>
      )}
      {showRefundModal && (
        <UserModal
          show={showRefundModal}
          onClose={toggleRefundModal}
          heading="Cancellation &amp; Refund"
          hideBtn
        >
          <p>
            Subscription once purchased <strong>cannot</strong> be cancelled.
          </p>
          <p>
            There is <strong>no</strong> provision of the subscription amount to
            be refunded.
          </p>
        </UserModal>
      )}
    </>
  );
};

const UserContainer = ({
  children,
  hideMain,
  hideHeader,
  wrapperClass,
  hideTitle,
  title,
}) => {
  return (
    <>
      <div className={`pageWrapper ${wrapperClass ? wrapperClass : ""}`}>
        {!hideHeader && <UserHeader />}
        <main
          style={{
            paddingBottom: 10,
            paddingTop: 1,
            backgroundImage: `url("${process.env.PUBLIC_URL}/assets/img/pattern.svg")`,
          }}
        >
          {!hideMain ? (
            <div className="container">
              <div id="wizard_container" className="wizard">
                <div
                  id="middlee-wizard"
                  className="wizard-branch wizard-wrapper"
                >
                  <div className="step wizard-step current">
                    {!hideTitle && (
                      <div className="question_title">
                        <h3 className="wizard-header">
                          {title
                            ? title
                            : `Welcome to ${AppConstant.APP_NAME}!`}
                        </h3>
                      </div>
                    )}
                    <div className="row">{children}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>{children}</>
          )}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default UserContainer;
