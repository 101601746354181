import { Account } from "../actionTypes/account";
import auth from "../libs/auth";

const initialState = {
  userData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Account.AUTH_SUCCESS:
      return {
        ...state,
        ...action.result,
        isLoggedIn: true,
      };
    case Account.SIGN_OUT_SUCCESS:
      auth.signout();
      return {
        ...initialState,
        isLoggedIn: false,
      };
    case Account.UPDATE_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.result,
        },
      };
    default:
      return state;
  }
};
