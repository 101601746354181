import { sendOTP, validateOTP } from "actions/user";
import { UserButton } from "components/Button";
import { TextInput } from "components/TextInput";
import { UserContainer } from "components/Container";
import useForm from "hooks/useForm";
import { useDispatch } from "react-redux";
import { signOut } from "actions/account";

const ParticipantQuizCompletedScreen = ({
  quizName,
  quizHostedId,
  hasReportPermission,
}) => {
  const dispatch = useDispatch();
  const { state, handleChange, handleSubmit, errors, setErrors } = useForm(
    {},
    { emailAddress: { required: true } },
    handleSave
  );
  const { emailAddress, showOtp, otp } = state;

  function handleSave(payload) {
    dispatch(
      sendOTP(payload.emailAddress, () => {
        handleChange("showOtp", true);
      })
    );
  }

  const handleVerify = () => {
    if (!otp) {
      setErrors({ otp: "OTP is required" });
      return;
    }
    if (emailAddress && otp) {
      dispatch(
        validateOTP({ emailAddress, otp, quizHostedId }, () => {
          dispatch(signOut());
        })
      );
    }
  };

  if (!hasReportPermission) {
    return (
      <UserContainer title={`${quizName} Completed`}>
        <></>
      </UserContainer>
    );
  }

  return (
    <UserContainer title={quizName}>
      <div className="centerAlign">
        <div className="item">
          <label labelfor="answer_2">
            <TextInput
              name="emailAddress"
              value={emailAddress}
              maxLength={50}
              placeholder="Enter your email for report"
              onChange={handleChange}
              errors={errors}
              disabled={showOtp}
            />
            <p>
              <UserButton
                type="submit"
                text="Send OTP"
                className="forward"
                onClick={handleSubmit}
              />
            </p>
            {showOtp && (
              <>
                <p>
                  <TextInput
                    name="otp"
                    value={otp}
                    maxLength={6}
                    placeholder="Enter OTP"
                    onChange={handleChange}
                    errors={errors}
                  />
                </p>
                <p>
                  <UserButton
                    type="submit"
                    text="Verify"
                    className="forward"
                    onClick={handleVerify}
                  />
                </p>
              </>
            )}
          </label>
        </div>
      </div>
    </UserContainer>
  );
};

export default ParticipantQuizCompletedScreen;
