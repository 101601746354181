import { useRef } from "react";
import { useDispatch } from "react-redux";
import { addEditPredefinedQuiz } from "actions/quiz";
import { getFormData } from "libs/functions";
import { RouteConstant } from "utils/routeConstant";
import { useNavigate, useParams } from "react-router-dom";
import { AddQuizDetail } from "./Components";

const AddSubscriptionScreen = ({ isQuizMaster, title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questionRef = useRef();
  const { quizId } = useParams();

  function handleSave(payload) {
    const questionList = questionRef.current?.getState();
    if (questionList) {
      dispatch(
        addEditPredefinedQuiz(
          getFormData({
            ...payload,
            isPredefined: !isQuizMaster,
            questionList,
          }),
          () => {
            navigate(
              isQuizMaster
                ? RouteConstant.QUIZZES
                : RouteConstant.PREDEFINED_QUIZZES
            );
          }
        )
      );
    }
  }

  return (
    <AddQuizDetail
      title={title}
      isQuizMaster={isQuizMaster}
      quizId={quizId}
      handleSave={handleSave}
      questionRef={questionRef}
    />
  );
};

export default AddSubscriptionScreen;
