import Timer from "common/Timer";
import { UserContainer } from "components/Container";
import useUserDetail from "hooks/useUserDetail";
import { AppConstant } from "utils/constant";

const ParticipantWaitScreen = ({ showTimer, onQuizStarted }) => {
  const { displayName } = useUserDetail();

  return (
    <UserContainer hideMain>
      <div className="waitScreenContainer">
        <div
          className="hero_in shortcodes"
          style={{
            backgroundImage: `url("${process.env.PUBLIC_URL}/assets/img/shortcodes_bg.svg")`,
          }}
        >
          <div></div>
        </div>
        <div style={{ paddingBottom: 10, paddingTop: 60 }} className="plain">
          <div className="container">
            <div
              id="wizard_container"
              style={{ minHeight: 200 }}
              className="wizard"
              novalidate="novalidate"
            >
              <div id="middlee-wizard" className="wizard-branch wizard-wrapper">
                <div
                  className="step wizard-step current"
                  data-state="branchtype"
                >
                  <div className="question_title">
                    <h2 className="wizard-header">
                      Hello,{" "}
                      <strong style={{ color: "#0171BD" }}>
                        {displayName}
                      </strong>
                      !
                    </h2>
                    {showTimer ? (
                      <h4 className="centerAlign">
                        Game starting in&nbsp;
                        <Timer
                          initialSeconds={AppConstant.QUIZ_INIT_TIME}
                          onFinish={onQuizStarted}
                        />
                        s
                      </h4>
                    ) : (
                      <>
                        <h4>The Quizmaster will start the game shortly...</h4>
                        <h6>Check your nickname on the screen.</h6>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserContainer>
  );
};

export default ParticipantWaitScreen;
