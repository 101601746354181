import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UserError, UserLabel } from "../Label";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import useToggle from "../../hooks/useToggle";
import { formatInputText } from "../../libs/functions";
import useToast from "hooks/useToast";

const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$");

const Input = (props) => {
  const {
    inputRef,
    name,
    type: initType,
    placeholder,
    maxLength,
    toUpper,
    className,
    pattern,
    disabled,
    multiple,
    suffix,
    value,
    onChange,
    error,
    minValue,
    maxValue,
    tabIndex,
  } = props;

  const { show, toggle, setShow } = useToggle();

  const [type, setType] = useState("text");

  useEffect(() => {
    if (initType) {
      setType(show ? "text" : initType);
    }
  }, [initType, show]);

  const showPassord = () => {
    setShow(true);
  };

  const hidePassword = () => {
    setShow(false);
  };

  const onClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <input
        onClick={onClick}
        ref={inputRef}
        id={name}
        type={type}
        name={`${name}`}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength ? maxLength : 200}
        value={toUpper && value ? value.toUpperCase() : value ? value : ""}
        className={`form-control ${className ? `${className}` : ""} ${
          error ? "errInput" : ""
        }`}
        autoComplete={`auto-${name}`}
        pattern={pattern ? pattern : null}
        disabled={disabled}
        multiple={multiple ? true : false}
        min={minValue}
        max={maxValue}
        tabIndex={tabIndex}
      />
      {/* {initType == "password" && !disabled && (
        <>
          {show ? (
            <AiFillEye
              className="passwordIc"
              onClick={toggle}
              onMouseLeave={hidePassword}
            />
          ) : (
            <AiFillEyeInvisible
              className="passwordIc"
              onClick={toggle}
              onMouseLeave={hidePassword}
            />
          )}
        </>
      )} */}
      <span className="txtSuffix">{suffix}</span>
    </>
  );
};

const getFile = (file, fileSize, cb) => {
  cb({
    file,
    fileName: file.name,
    length: fileSize,
    fileType: file.type,
  });
};

const TextInput = (props) => {
  const { showError } = useToast();

  const onFieldChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    const {
      type,
      multiple,
      onChange,
      formatText: format,
      maxValue,
      minValue,
      maxValueError,
    } = props;
    if (type === "file") {
      const selectedFiles = Array.from(target.files);
      let files = [];
      selectedFiles.forEach((file, index) => {
        const fileSize = file.size / 1024 / 1024;
        getFile(file, fileSize, (data) => {
          files.push(data);
          if (selectedFiles.length == files.length)
            onChange(name, multiple ? files : data);
        });
      });
    } else {
      if (format) value = formatInputText(value);
      if (value && minValue && value < minValue) return;
      if (value && maxValue && value > maxValue) {
        if (maxValueError) {
          showError(maxValueError);
        }
        return;
      }
      if (type == "float" && value && !floatRegExp.test(value)) return;
      onChange(name, value);
    }
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result.split(",")[1]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const { errors, label, name, icon } = props;
  const error = errors ? errors[name] : null;

  return (
    <div className={`form-group${label ? " form-md-line-input" : ""}`}>
      {icon ? (
        <div className="input-icon">
          {icon}
          <Input {...props} onChange={onFieldChange} error={error} />
        </div>
      ) : (
        <Input {...props} onChange={onFieldChange} error={error} />
      )}
      {label && <UserLabel {...props} />}
      <UserError error={error} />
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  alhpaNumeric: PropTypes.bool,
  button: PropTypes.func,
  url: PropTypes.string,
  error: PropTypes.string,
  link: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  class: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  toUpper: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextInput;
