import React, { useEffect, useState } from "react";

const useScreenSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return {
    isMobileView: width <= 480,
    screenWidth: width,
    showMenuAtTop: width <= 990,
  };
};

export default useScreenSize;
