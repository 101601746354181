import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Loading from "common/Loading";
import APIResponse from "common/APIResponse";
import { DashboardScreen } from "containers/Home";

const App = (props) => {
  return (
    <div>
      <DashboardScreen />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <APIResponse />
      <Loading />
    </div>
  );
};

export default App;
