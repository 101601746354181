import {
  getHostedQuizDetail,
  getQuizParticipants,
  startQuiz,
} from "actions/quiz";
import { UserButton } from "components/Button";
import { Container } from "components/Card";
import { UserQrCode } from "components/QrCode";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RouteConstant } from "utils/routeConstant";

const baseUrl = window.location.origin;

const ParticipantScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quizHostedId } = useParams();
  const { hostedQuiz, participantList } = useSelector((state) => state.quiz);
  const { quizCode, noOfParticipants } = hostedQuiz ? hostedQuiz : {};

  useEffect(() => {
    dispatch(getHostedQuizDetail(quizHostedId));
  }, [quizHostedId]);

  useEffect(() => {
    dispatch(getQuizParticipants(quizHostedId));
  }, [quizHostedId]);

  useEffect(() => {
    if (participantList && participantList.length == noOfParticipants) {
      handleStart();
    }
  }, [participantList, noOfParticipants]);

  const handleStart = () => {
    dispatch(startQuiz(quizHostedId));
    navigate(`${RouteConstant.QUIZ_PARTICIPANT_RANK}/${quizHostedId}`);
  };

  // const url = `${baseUrl}${RouteConstant.QUIZ_CODE}`;

  return (
    <Container title="Host Game">
      <div className="col-12">
        <div className="row">
          <div className="UserContainer col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <br />
            <h5>
              <b>Scan the QR Or visit {baseUrl}</b>
            </h5>
            <br />
            <div className="qrBlock">
              <UserQrCode
                value={`${baseUrl}${RouteConstant.SIGN_UP_PARTICIPANT}/${quizHostedId}`}
              />
            </div>
            <br />
            <div className="quizCodeBlock">
              <center>
                <h2>{quizCode}</h2>
              </center>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 participantListWrapper">
            <h3>Participants</h3>
            <ul className="list-group">
              {participantList &&
                participantList.map((p, index) => (
                  <li
                    key={`P${index}`}
                    className="list-group-item"
                    style={{ backgroundColor: p.colourCode, color: "white" }}
                  >
                    {p.name}
                  </li>
                ))}
            </ul>
          </div>
          <div className="col-12 mt-2">
            <center>
              <UserButton text="Play" onClick={handleStart} />
            </center>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ParticipantScreen;
