import React from "react";
import { Link } from "react-router-dom";

const UserLink = ({
  text,
  className,
  disabled = false,
  onClick,
  icon,
  showIconFirst,
  value,
  title,
  href,
  download,
  target,
}) => {
  let btnText = showIconFirst ? (
    <>
      {icon ? icon : ""} <span className="btext">{text}</span>
    </>
  ) : (
    <>
      {text} {icon ? icon : ""}
    </>
  );
  return (
    <Link
      disabled={disabled}
      title={title}
      value={value}
      className={
        disabled ? "btn-disabled" : className ? className : "btn btn-primary"
      }
      onClick={onClick}
      to={href ? href : ""}
      download={download}
      target={target}
    >
      {btnText}
    </Link>
  );
};

export default UserLink;
