import { getHostedQuizDetail, getQuizParticipants } from "actions/quiz";
import { addEditUserQuestion, getQuizQuestion } from "actions/user";
import Timer from "common/Timer";
import { UserButton } from "components/Button";
import { UserContainer } from "components/Container";
import { UserRadioButton } from "components/RadioButton";
import useForm from "hooks/useForm";
import APIUtil from "api/APIUtil";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppConstant } from "utils/constant";
import useToggle from "hooks/useToggle";
import {
  ParticipantWaitScreen,
  ParticipantQuizCompletedScreen,
} from "./Components";

const baseUrl = APIUtil.getBaseUrl();

const AnswerLabel = ({ answerText, answerFile }) => {
  const { filePath } = answerFile ? answerFile : {};

  return (
    <>
      {filePath && (
        <div className="imgWrapper">
          <img src={`${baseUrl}${filePath}`} />
        </div>
      )}
      <strong> {answerText}</strong>
    </>
  );
};

const ParticipantQuestionScreen = () => {
  const dispatch = useDispatch();
  const timerRef = useRef();
  const { quizHostedId } = useParams();
  const { quizQuestion, isQuizCompleted } = useSelector((state) => state.user);
  const { hostedQuiz } = useSelector((state) => state.quiz);
  const { quizStatus, timePerQuestion, quizName, hasReportPermission } =
    hostedQuiz ? hostedQuiz : {};
  const { show: showTimer, setShow: setTimer } = useToggle();
  const { state, setState, handleChange, handleSubmit } = useForm(
    {},
    { answerId: { required: true } },
    handleNext
  );
  const { questionText, qNo, answerList, questionId, questionFile } =
    quizQuestion ? quizQuestion : {};
  const { filePath: questionFilePath } = questionFile ? questionFile : {};
  const { answerId } = state;

  useEffect(() => {
    dispatch(getQuizQuestion({ quizHostedId }));
    dispatch(getHostedQuizDetail(quizHostedId));
  }, []);

  useEffect(() => {
    setTimer(quizStatus == AppConstant.QUIZ_STATUS_STARTED);
  }, [quizStatus]);

  // useEffect(() => {
  //   dispatch(getQuizParticipants(quizHostedId));
  // }, []);

  // useEffect(() => {
  //   if (
  //     participantList &&
  //     participantList.length > 0 &&
  //     answerList &&
  //     answerList.length > 0
  //   ) {
  //     participantList.forEach(({ userId }) => {
  //       setTimeout(() => {
  //         handleNext(
  //           {
  //             answerId: answerList[Math.floor(Math.random() * 3)].answerId,
  //             userId,
  //           },
  //           { questionId }
  //         );
  //       }, 300);
  //     });
  //   }
  // }, [participantList, questionId, answerList]);

  function handleNext(payload, { questionId }) {
    if (questionId) {
      dispatch(
        addEditUserQuestion({
          ...payload,
          questionId,
          quizHostedId,
          timeRemainingInSeconds: timerRef.current
            ? timerRef.current.getTimeRemaining()
            : 0,
        })
      );
      setState({});
    }
  }

  const onQuizStarted = () => {
    setTimer(false);
  };

  const onNext = (event) => {
    handleSubmit(event, { questionId });
  };

  const onFinish = () => {
    handleNext(state, { questionId });
  };

  if (quizStatus == AppConstant.QUIZ_STATUS_COMPLETED || isQuizCompleted) {
    return (
      <ParticipantQuizCompletedScreen
        quizName={quizName}
        quizHostedId={quizHostedId}
        hasReportPermission={hasReportPermission}
      />
    );
  }

  if (!quizStatus || !questionId || showTimer) {
    return (
      <ParticipantWaitScreen
        showTimer={showTimer}
        onQuizStarted={onQuizStarted}
      />
    );
  }

  return (
    <UserContainer hideTitle>
      <div className="timerWrapper">
        <Timer
          initialSeconds={timePerQuestion}
          onFinish={onFinish}
          resetTime={questionId}
          ref={timerRef}
        />
      </div>
      <div className="questionsContainer">
        <div id="wizard_container">
          <div id="middle-wizard">
            <div className="question_title">
              {questionFilePath && (
                <div className="imgWrapper">
                  <img src={`${baseUrl}${questionFilePath}`} />
                </div>
              )}
              <h3 class="wizard-header">{questionText}</h3>
            </div>
            <div className="row">
              {answerList &&
                answerList.map((a, index) => (
                  <div className="col-lg-3" key={`A${index}`}>
                    <div className="item">
                      <UserRadioButton
                        name="answerId"
                        id={`A${index}`}
                        value={a.answerId}
                        checked={a.answerId == answerId}
                        onChange={handleChange}
                        label={<AnswerLabel key={`A${index}`} {...a} />}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div id="bottom-wizard">
            <UserButton text="Next" onClick={onNext} />
          </div>
        </div>
      </div>
    </UserContainer>
  );
};

export default ParticipantQuestionScreen;
