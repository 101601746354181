import { Quiz } from "actionTypes/quiz";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case Quiz.ADD_QUIZ_VIEW:
      return {
        ...state,
        quizDetail: null,
        questionList: null,
      };
    case Quiz.QUIZ_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
    default:
      return {
        ...state,
      };
  }
};
