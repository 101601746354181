import { AppConstant } from "utils/constant";

const { USER_KEY } = AppConstant;

class auth {
  get isAuthenticated() {
    return !!localStorage.getItem(USER_KEY);
  }
  get userKey() {
    return localStorage.getItem(USER_KEY);
  }

  setItem(key, data) {
    localStorage.setItem(key, data);
  }

  signout() {
    localStorage.removeItem(USER_KEY);
  }

  signIn(user, cb) {
    localStorage.setItem(USER_KEY, user);
  }
}

export default new auth();
