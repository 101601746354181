import APIUtil from "api/APIUtil";
import { UserCheckbox } from "components/Checkbox";
import { UserSelect } from "components/SelectBox";
import { UserTable } from "components/Table";
import { TextInput, UploadFile } from "components/TextInput";
import useGrid from "hooks/useGrid";
import useToast from "hooks/useToast";
import { getUniqueList } from "libs/functions";
import React, { useCallback, useEffect, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { cloneDeep } from "lodash";
import { GridButton } from "components/Table/TableActions";

const answerCount = 4,
  inputCount = 6;

const headers = [
  {
    key: "qNo",
    text: "Q No.",
  },
  {
    key: "questionText",
    text: "Question",
  },
  ...[...Array(answerCount)].map((c, index) => ({
    key: `Answer${index + 1}`,
    text: `Answer${index + 1}`,
  })),
  { key: "correctAnswer", text: "Correct Answer" },
  {
    key: "actions",
    text: "Actions",
  },
];

const rowId = "qNo";

const baseUrl = APIUtil.getBaseUrl();

const GridFileInput = ({
  name,
  value,
  fileValue,
  handleChange,
  placeholder,
  rowId,
  tabIndex,
}) => {
  const onChange = (name, value) => {
    handleChange(rowId, name, value);
  };

  return (
    <div className="inputWrapper">
      <TextInput
        name={`${name}Text`}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        tabIndex={tabIndex}
      />
      {fileValue && (
        <img
          src={
            fileValue.file
              ? URL.createObjectURL(fileValue.file)
              : `${baseUrl}${fileValue.filePath}`
          }
          width={100}
          height={100}
          style={{ marginBottom: 5 }}
        />
      )}
      <UploadFile
        fileSrcName={`${name}File`}
        label=" "
        onChange={onChange}
        files={fileValue}
      />
    </div>
  );
};

const GridSelect = ({
  name,
  value,
  type,
  placeholder,
  handleChange,
  rowId,
  options,
  tabIndex,
}) => {
  const onChange = (name, value) => {
    handleChange(rowId, name, value);
  };

  return (
    <UserSelect
      name={name}
      value={value}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      tabIndex={tabIndex}
    />
  );
};

const GridCheckbox = ({ name, value, handleChange, rowId }) => {
  const onChange = (name1, value) => {
    handleChange(rowId, name, !value);
  };

  return (
    <UserCheckbox name={`${rowId}_${name}`} value={value} onChange={onChange} />
  );
};

export default React.forwardRef((props, ref) => {
  const { questionList } = useSelector((state) => state.quiz);
  const { showError } = useToast();
  const {
    gridData,
    setGridData,
    handleChange: handleRowChange,
  } = useGrid(rowId);

  useEffect(() => {
    if (questionList && questionList.length > 0) {
      setGridData(questionList);
    } else {
      const answerList = [...Array(answerCount)].map((c) => ({ answer: "" }));
      setGridData(
        [...Array(5)].map((q, index) => ({
          qNo: index + 1,
          answerList,
        }))
      );
    }
  }, [questionList]);

  useImperativeHandle(ref, () => ({
    getState() {
      if (!gridData || gridData.length == 0) {
        showError("Please upload questions file");
        return;
      }
      const errorQuestion = gridData.find(
        (c) =>
          !c.questionText ||
          c.answerList.some((a) => !a.answerText) ||
          !c.correctAnswer
      );
      if (errorQuestion) {
        showError(
          `Incomplete Question / Answers. Rectify the problem at Q.No.: ${errorQuestion.qNo}`
        );
        return;
      }
      const duplicateAnswers = gridData.find(
        (c) =>
          getUniqueList(c.answerList, "answerText").length !=
          c.answerList?.length
      );
      if (duplicateAnswers) {
        showError(
          `Duplicate Answers. Rectify the problem at Q.No.: ${duplicateAnswers.qNo}`
        );
        return;
      }

      return gridData;
    },
  }));

  const handleGridChange = (id, name, value) => {
    var rowIdArr = id.toString().split("_");
    if (rowIdArr.length > 1) {
      let answerList = gridData
        .find((c) => c[rowId] == rowIdArr[0])
        .answerList.slice();
      answerList[rowIdArr[1]] = { ...answerList[rowIdArr[1]], [name]: value };
      handleRowChange(parseInt(rowIdArr[0]), "answerList", answerList);
    } else {
      handleRowChange(id, name, value);
    }
  };

  const hasError = ({ answerList, questionText, correctAnswer }) => {
    if (
      !questionText ||
      answerList.some((s) => !s.answerText) ||
      !correctAnswer
    ) {
      return true;
    }
    if (getUniqueList(answerList, "answerText").length != answerList?.length) {
      return true;
    }
  };

  const handleDelete = (id) => {
    let list = cloneDeep(gridData);
    const index = list.findIndex((c) => c[rowId] == id);
    if (index > -1) {
      const { qNo } = list[index];
      list.forEach((item) => {
        if (item.qNo > qNo) {
          item.qNo = item.qNo - 1;
        }
      });
      list.splice(index, 1);
      setGridData(list);
    }
  };

  const handleAdd = (id) => {
    let list = cloneDeep(gridData);
    const index = list.findIndex((c) => c[rowId] == id);
    if (index > -1) {
      const { qNo } = list[index];
      list.forEach((item) => {
        if (item.qNo > qNo) {
          item.qNo = item.qNo + 1;
        }
      });
      list.splice(index + 1, 0, {
        qNo: qNo + 1,
        answerList: [...Array(answerCount)].map((c) => ({ answer: "" })),
      });
      setGridData(list);
    }
  };

  const rows = useCallback(() => {
    return gridData.map((c, index) => ({
      ...c,
      rowProps: { className: hasError(c) ? "errorRow" : "" },
      questionText: (
        <GridFileInput
          rowId={c[rowId]}
          name="question"
          key={`Q${index}`}
          value={c.questionText}
          fileValue={c.questionFile}
          handleChange={handleGridChange}
          tabIndex={index * inputCount + 1}
        />
      ),
      ...c.answerList.reduce(
        (prev, curr, aindex) => ({
          ...prev,
          [`Answer${aindex + 1}`]: (
            <GridFileInput
              rowId={c[rowId] + "_" + aindex}
              tabIndex={index}
              key={`Q${index}_A${aindex}`}
              name="answer"
              value={curr.answerText}
              fileValue={curr.answerFile}
              handleChange={handleGridChange}
              tabIndex={index * inputCount + aindex + 1}
            />
          ),
        }),
        {}
      ),
      correctAnswer: (
        <GridSelect
          rowId={c[rowId]}
          name="correctAnswer"
          value={c.correctAnswer}
          key={`S${index}`}
          placeholder="Select Answer"
          options={c.answerList.map((c, aIndex) => ({
            text: c.answerText,
            value: aIndex + 1,
          }))}
          handleChange={handleGridChange}
          tabIndex={index * inputCount + inputCount}
        />
      ),
      actions: (
        <div key={`A${index}`} className="actionsRow">
          <GridCheckbox
            rowId={c[rowId]}
            name="isInActive"
            value={!c.isInActive}
            handleChange={handleGridChange}
          />
          <GridButton
            rowId={c[rowId]}
            icon={<AiFillDelete fontSize={24} color="red" />}
            handleClick={handleDelete}
          />
          <GridButton
            rowId={c[rowId]}
            icon={<AiOutlinePlusCircle fontSize={24} color="#45B6AF" />}
            handleClick={handleAdd}
          />
        </div>
      ),
    }));
  }, [gridData]);

  return (
    <>
      {gridData.length > 0 && (
        <div className="col-12 questionTbl">
          <UserTable headers={headers} data={rows()} rowId={rowId} />
        </div>
      )}
    </>
  );
});
