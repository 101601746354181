import React, { useEffect, useState } from "react";
import { RouteConstant } from "utils/routeConstant";
import UserLink from "../Link/UserLink";
import useUserDetail from "hooks/useUserDetail";
import { AiOutlineSetting } from "react-icons/ai";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdDashboard,
} from "react-icons/md";
import { FaGamepad } from "react-icons/fa";
import { BiMenu } from "react-icons/bi";
import useScreenSize from "hooks/useScreenSize";

const {
  SUBSCRIPTION,
  ADD_PREDEFINED_QUIZ,
  PREDEFINED_QUIZZES,
  ADD__QUIZ,
  QUIZZES,
  REPORT,
  USER_QUIZ_CODE,
  TEST_QUIZZES,
} = RouteConstant;

const Menu = ({
  href,
  menuName,
  onClick,
  isActive,
  hasSubMenu,
  children,
  isSubMenu,
  icon,
}) => {
  const handleClick = (event) => {
    if (event) {
      event.stopPropagation();
    }
    onClick(menuName);
  };

  return (
    <li
      className={`${isActive ? "active" : ""}${
        hasSubMenu && isActive ? " open" : ""
      }`}
      onClick={handleClick}
    >
      <UserLink
        className={` ${isActive && !hasSubMenu ? "current-page" : ""}`}
        href={href ? href : "#"}
        text={
          !isSubMenu ? (
            <>
              {icon}
              <span className="title">{menuName}</span>
              {hasSubMenu && (
                <>
                  {isActive ? (
                    <MdKeyboardArrowDown
                      className="arrow pull-right"
                      size={20}
                    />
                  ) : (
                    <MdKeyboardArrowRight
                      className="arrow pull-right"
                      size={20}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>{menuName}</>
          )
        }
      />
      {children}
    </li>
  );
};

const adminMenuList = [
  { name: "Dashboard", icon: <MdDashboard />, url: REPORT },
  {
    name: "Masters",
    icon: <AiOutlineSetting />,
    subMenuList: [
      { name: "Subscription", url: SUBSCRIPTION },
      { name: "Create Games", url: ADD_PREDEFINED_QUIZ },
      { name: "List of Games", url: PREDEFINED_QUIZZES },
      { name: "Test Quiz", url: TEST_QUIZZES },
    ],
  },
  // { name: "Host Game", icon: <FaGamepad />, url: HOST_QUIZ },
  // { name: "List of Users", icon: <FaUsers /> },
  // { name: "List of Participants", icon: <FaUsers /> },
  // { name: "List of Games", icon: <AiOutlineOrderedList /> },
];

const userMenuList = [
  { name: "Dashboard", icon: <MdDashboard />, url: REPORT },
  {
    name: "Masters",
    icon: <AiOutlineSetting />,
    subMenuList: [
      { name: "Create Games", url: ADD__QUIZ },
      { name: "List of Games", url: QUIZZES },
    ],
  },
  // { name: "List of Users", icon: <FaUsers /> },
  // { name: "List of Participants", icon: <FaUsers /> },
];

const participantMenuList = [
  { name: "Play Game", icon: <FaGamepad />, url: USER_QUIZ_CODE },
];

export default ({ onClick, showMenu }) => {
  const { isAdmin, isUser } = useUserDetail();
  const [selectedMenu, setSelectedMenu] = useState([]);
  const { showMenuAtTop } = useScreenSize();

  useEffect(() => {
    let currentUrl = window.location.pathname,
      menuList = [];
    if (isAdmin) {
      currentUrl = REPORT;
      menuList = adminMenuList;
    } else if (isUser) {
      currentUrl = REPORT;
      menuList = userMenuList;
    } else {
      currentUrl = USER_QUIZ_CODE;
      menuList = participantMenuList;
    }
    const selectedMenu =
      menuList.find((c) => c.url == currentUrl)?.name ??
      menuList
        .filter((c) => c.subMenuList)
        .map((c) => c.subMenuList)
        .flat(1)
        .find((c) => c.url == currentUrl)?.name;
    setSelectedMenu(selectedMenu);
  }, []);

  const handleClick = (menuName) => {
    setSelectedMenu(selectedMenu == menuName ? null : menuName);
  };

  return (
    <div className="page-sidebar-wrapper">
      <div
        className={`page-sidebar navbar-collapse ${
          showMenuAtTop && showMenu
            ? "collapse-in"
            : showMenuAtTop
            ? "collapse"
            : ""
        }`}
      >
        {(isAdmin || isUser) && (
          <ul
            className={`page-sidebar-menu page-sidebar-menu-light${
              !showMenu ? " page-sidebar-menu-closed" : ""
            }`}
          >
            <li className="sidebar-toggler-wrapper">
              <div className="sidebar-toggler">
                <BiMenu onClick={onClick} />
              </div>
            </li>
            {(isAdmin ? adminMenuList : userMenuList).map((c, index) => (
              <Menu
                key={`M${index}`}
                menuName={c.name}
                href={c.url}
                icon={c.icon}
                isActive={
                  selectedMenu == c.name ||
                  (c.subMenuList &&
                    c.subMenuList.some((s) => s.name == selectedMenu))
                }
                onClick={handleClick}
                hasSubMenu={c.subMenuList && c.subMenuList.length > 0}
              >
                {c.subMenuList && (
                  <ul className="sub-menu">
                    {c.subMenuList.map((s, sIndex) => (
                      <Menu
                        key={`M${index}_SM${sIndex}`}
                        menuName={s.name}
                        href={s.url}
                        isActive={selectedMenu == s.name}
                        onClick={handleClick}
                        isSubMenu
                      />
                    ))}
                  </ul>
                )}
              </Menu>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
