import { HubConnectionBuilder } from "@microsoft/signalr";
import {
  getHostedQuizDetail,
  getQuizParticipantRanks,
  getQuizParticipants,
} from "actions/quiz";
import { getQuizQuestion } from "actions/user";
import APIUtil from "api/APIUtil";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import auth from "libs/auth";

const baseUrl = APIUtil.getBaseUrl();

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const useSignalR = (isLoggedIn) => {
  const [connection, setConnection] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${baseUrl}hubs/quiz`, {
          accessTokenFactory: () => auth.userKey,
        })
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on("ReceiveQuestions", (hostedQuiz) => {
            if (hostedQuiz) {
              dispatch(
                getQuizQuestion({ quizHostedId: hostedQuiz.quizHostedId })
              );
              dispatch(getHostedQuizDetail(hostedQuiz.quizHostedId));
            }
          });
          connection.on("ReceiveParticipants", (quizHostedId) => {
            const debounceFn = debounce(() => {
              dispatch(getQuizParticipants(quizHostedId));
            }, 1000);
            debounceFn();
          });
          connection.on("ReceiveParticipantRanks", (quizHostedId) => {
            const debounceFn = debounce(() => {
              dispatch(getQuizParticipantRanks(quizHostedId));
            }, 1000);
            debounceFn();
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);
};

export default useSignalR;
