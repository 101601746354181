export const Account = {
  AUTH_USER: "AUTH_USER",
  SIGN_UP_PARTICIPANT: "SIGN_UP_PARTICIPANT",
  SIGN_UP_USER: "SIGN_UP_USER",
  SEND_NEW_USER_OTP: "SEND_NEW_USER_OTP",
  VALIDATE_USER: "VALIDATE_USER",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  SIGN_OUT: "SIGN_OUT",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
};
