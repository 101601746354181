import { getQuizParticipants } from "actions/quiz";
import { addEditUserQuestion, getQuizQuestion } from "actions/user";
import { UserTable } from "components/Table";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ParticipantRankScreen from "./ParticipantRankScreen";

const headers = [
  {
    key: "name",
    text: "Name",
  },
  {
    key: "correctAnswerCount",
    text: "Correct Answers",
  },
  {
    key: "score",
    text: "Score",
  },
  {
    key: "timeRemainingInSeconds",
    text: "Time Left(s)",
  },
  {
    key: "totalScore",
    text: "Total Score",
  },
];

const ParticipantList = () => {
  const { participantRankList } = useSelector((state) => state.quiz);

  return (
    <div className="row">
      <div className="col-12" style={{ marginTop: 40 }}>
        <UserTable headers={headers} data={participantRankList} />
      </div>
    </div>
  );
};

const TestParticipantScreen = (props) => {
  const dispatch = useDispatch();
  const { quizHostedId } = useParams();
  const { hostedQuiz } = useSelector((state) => state.quiz);
  const { timePerQuestion } = hostedQuiz ? hostedQuiz : {};

  useEffect(() => {
    dispatch(getQuizParticipants(quizHostedId));
  }, []);

  const { participantList } = useSelector((state) => state.quiz);

  useEffect(() => {
    if (hostedQuiz && participantList && participantList.length > 0) {
      participantList.forEach(({ userId }) => {
        dispatch(
          getQuizQuestion({ quizHostedId, userId }, (result) => {
            submitQuestion(result, userId);
          })
        );
      });
    }
  }, [participantList, hostedQuiz]);

  function submitQuestion({ quizQuestion }, userId) {
    const { answerList, questionId } = quizQuestion ? quizQuestion : {};
    if (answerList && answerList.length > 0) {
      setTimeout(() => {
        handleNext(
          {
            answerId: answerList[Math.floor(Math.random() * 3)].answerId,
            userId,
            timeRemainingInSeconds: Math.floor(Math.random() * timePerQuestion),
          },
          { questionId }
        );
      }, 7000);
    }
  }

  function handleNext(payload, { questionId }) {
    if (questionId) {
      dispatch(
        addEditUserQuestion(
          {
            ...payload,
            questionId,
            quizHostedId,
          },
          (result) => submitQuestion(result, payload.userId)
        )
      );
    }
  }
  return (
    <>
      <ParticipantRankScreen {...props} />
      <ParticipantList />
    </>
  );
};

export default TestParticipantScreen;
