import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "actions/account";
import { TextInput } from "components/TextInput";
import { UserButton } from "components/Button";
import useForm from "hooks/useForm";
import { RouteConstant } from "utils/routeConstant";
import { useLocation } from "react-router-dom";
import UserLink from "components/Link/UserLink";
import { FaLock, FaUser } from "react-icons/fa";
import { LoginWrapper } from "./Components";
import useDefaultPage from "hooks/useDefaultPage";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { state, errors, handleChange, handleSubmit } = useForm(
    {},
    { userName: { required: true }, password: { required: true } },
    handleLogin
  );
  useDefaultPage();
  const location = useLocation();
  const { userName, password } = state;

  useEffect(() => {
    const userName = location.state?.userName;
    if (userName) {
      handleChange("userName", userName);
    }
  }, []);

  function handleLogin(payload) {
    dispatch(loginUser(payload));
  }

  return (
    <LoginWrapper title="Quizmaster’s Login">
      <div className="form-group">
        <TextInput
          name="userName"
          value={userName}
          maxLength={50}
          asterix
          placeholder="Username"
          onChange={handleChange}
          errors={errors}
          icon={<FaUser />}
        />
      </div>
      <div className="form-group">
        <TextInput
          name="password"
          type="password"
          value={password}
          maxLength={50}
          asterix
          placeholder="Password"
          onChange={handleChange}
          errors={errors}
          icon={<FaLock />}
        />
      </div>
      <div className="form-actions">
        <UserButton
          type="submit"
          text="LOGIN"
          className="btn green-haze pull-right"
          onClick={handleSubmit}
        />
      </div>
      <div className="forget-password">
        <h4>Forgot your password ?</h4>
        <p>
          no worries, click{" "}
          <UserLink
            href={RouteConstant.FORGOT_PASSWORD}
            text="here "
            className=" "
          />
          to reset your password.
        </p>
      </div>
      <div className="create-account">
        <p>
          Don't have an account yet ?&nbsp;&nbsp;
          <UserLink
            href={RouteConstant.SIGN_UP}
            text="Introductory Offer! Become ROIMOI Quizmaster for Free! Offer valid till 15th August, 2023"
            className=" "
          />
        </p>
      </div>
    </LoginWrapper>
  );
};

export default LoginScreen;
