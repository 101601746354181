import { ExportCSV } from "components/Document";
import { UserTable } from "components/Table";
import { formatDisplayDate } from "libs/functions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const headers = [
  {
    key: "name",
    text: "Name",
  },
  {
    key: "email",
    text: "Email",
  },
  {
    key: "quizName",
    text: "Game",
  },
  {
    key: "hostedOn",
    text: "Hosted On",
  },
  {
    key: "questionCount",
    text: "Correct Answers",
  },
  {
    key: "score",
    text: "Score",
  },
];

const ParticipantList = () => {
  const [gridData, setGridData] = useState([]);
  const { participantList } = useSelector((state) => state.report);

  useEffect(() => {
    if (participantList)
      setGridData(
        participantList.map((c) => ({
          ...c,
          hostedOn: formatDisplayDate(c.hostedOn),
        }))
      );
  }, [participantList]);

  return (
    <>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-4 mb-2">
        <div className="pull-right">
          <ExportCSV
            btnText="Download"
            filename="Participants.csv"
            headers={headers.map((c) => ({ ...c, label: c.text }))}
            data={gridData}
          />
        </div>
      </div>
      <div className="col-12">
        <UserTable headers={headers} data={gridData} />
      </div>
    </>
  );
};

export default ParticipantList;
