import { sendNewUserOtp, validateUser } from "actions/account";
import Timer from "common/Timer";
import { UserButton } from "components/Button";
import { TextInput } from "components/TextInput";
import useForm from "hooks/useForm";
import useToggle from "hooks/useToggle";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RouteConstant } from "utils/routeConstant";
import { LoginWrapper } from "./Components";

const ValidateUserScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emailAddress } = useParams();
  const { show: disableResend, toggle: toggleResend } = useToggle(true);
  const { state, errors, handleChange, handleSubmit } = useForm(
    {},
    { otp: { required: true } },
    handleVerify
  );
  const { otp } = state;

  const onFinish = () => {
    toggleResend();
  };

  const handleResend = () => {
    dispatch(sendNewUserOtp(emailAddress));
    toggleResend();
  };

  function handleVerify(payload) {
    const { otp } = payload;
    dispatch(
      validateUser({ emailAddress, otp }, () => {
        navigate(RouteConstant.LOGIN);
      })
    );
  }

  return (
    <LoginWrapper title="Verify Email">
      <div className="form-group">
        <TextInput
          name="emailAddress"
          value={emailAddress}
          label="Email Id"
          onChange={handleChange}
          disabled
        />
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-8">
            <TextInput
              name="otp"
              value={otp}
              maxLength={6}
              asterix
              placeholder="Enter OTP"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          {disableResend && (
            <div className="col-4 timerWrapper">
              <Timer
                initialSeconds={20}
                onFinish={onFinish}
                resetTime={disableResend}
              />
            </div>
          )}
        </div>
      </div>
      <div className="actions mb-4">
        <UserButton
          type="button"
          text="Resend OTP"
          className="btn green-haze"
          onClick={handleResend}
          disabled={disableResend}
        />
        <UserButton
          type="submit"
          text="Verify"
          className="btn green-haze ml-10"
          onClick={handleSubmit}
        />
      </div>
    </LoginWrapper>
  );
};

export default ValidateUserScreen;
