import APIUtil from "./APIUtil";

class UserAPI {
  addEditQuizQuestion(payload) {
    return APIUtil.callPostAPI("api/User/AddEditQuizQuestion", payload);
  }

  getQuizQuestion(payload) {
    return APIUtil.callPostAPI("api/User/GetQuizQuestion", payload);
  }

  sendOTP(payload) {
    return APIUtil.callPostAPI("api/User/SendOTP", payload);
  }

  validateOTP(payload) {
    return APIUtil.callPostAPI("api/User/ValidateOTP", payload);
  }
}

export default new UserAPI();
