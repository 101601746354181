import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "utils/routeConstant";
import useUserDetail from "./useUserDetail";

const useDefaultPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.account);
  const { isAdmin, isUser } = useUserDetail();

  useEffect(() => {
    if (isLoggedIn) {
      if (isAdmin) {
        navigate(RouteConstant.REPORT);
      } else if (isUser) {
        navigate(RouteConstant.REPORT);
      } else {
        navigate(`${RouteConstant.USER_QUIZ_QUESTIONS}/0`);
      }
    }
  }, [isLoggedIn, isAdmin]);
};

export default useDefaultPage;
