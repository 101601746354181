import React from "react";
import { useDispatch } from "react-redux";
import { signUpUser } from "actions/account";
import { TextInput } from "components/TextInput";
import { UserButton } from "components/Button";
import useForm from "hooks/useForm";
import { RegxConstant } from "utils/regxConstant";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "utils/routeConstant";
import { LoginWrapper } from "./Components";
import UserLink from "components/Link/UserLink";

const validationSchema = {
  firstName: { required: true },
  lastName: { required: true },
  emailAddress: {
    required: true,
    validator: {
      regEx: RegxConstant.EMAIL,
      error: "Please enter valid email",
    },
  },
  password: { required: true },
  contactNumber: {
    validator: {
      regEx: RegxConstant.MOBILE_NO,
      error: "Please enter 10 digit mobile number.",
    },
  },
};

const SignUpScreen = () => {
  const dispatch = useDispatch();
  const { state, errors, handleChange, handleSubmit } = useForm(
    {},
    validationSchema,
    handleSignUp
  );
  const navigate = useNavigate();
  const { firstName, lastName, emailAddress, password, contactNumber } = state;

  function handleSignUp(payload) {
    dispatch(
      signUpUser(payload, () => {
        navigate(`${RouteConstant.VERIFY_ACCOUNT}/${payload.emailAddress}`);
      })
    );
  }

  return (
    <LoginWrapper title="Please SignUp">
      <div className="form-group">
        <TextInput
          name="firstName"
          value={firstName}
          maxLength={50}
          placeholder="First Name"
          label="First Name"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="form-group">
        <TextInput
          name="lastName"
          value={lastName}
          maxLength={50}
          placeholder="Last Name"
          label="Last Name"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="form-group">
        <TextInput
          name="emailAddress"
          value={emailAddress}
          maxLength={50}
          placeholder="Email"
          label="Email"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="form-group">
        <TextInput
          name="password"
          type="password"
          value={password}
          maxLength={50}
          placeholder="Password"
          label="Password"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="form-group">
        <TextInput
          name="contactNumber"
          value={contactNumber}
          maxLength={10}
          placeholder="Mobile Number"
          label="Mobile Number"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="form-actions">
        <UserButton
          type="submit"
          text="Sign Up"
          className="btn green-haze pull-right"
          onClick={handleSubmit}
        />
      </div>
      <div className="create-account">
        <p>
          Already have an account ?&nbsp;&nbsp;
          <UserLink href={RouteConstant.LOGIN} text="Login" className=" " />
        </p>
      </div>
    </LoginWrapper>
  );
};

export default SignUpScreen;
