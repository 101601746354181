import { takeLatest, call, put } from "redux-saga/effects";
import QuizAPI from "api/QuizAPI";
import { Quiz } from "actionTypes/quiz";
import callAPI from "./helper";
import { push } from "redux-first-history";
import { RouteConstant } from "utils/routeConstant";

function* addQuizView(action) {
  yield call(callAPI, QuizAPI.addQuizView, action, setResult);
}

function* getPredefinedQuizzes(action) {
  yield call(callAPI, QuizAPI.getPredefinedQuizzes, action, setResult);
}

function* getAllQuizzes(action) {
  yield call(callAPI, QuizAPI.getAllQuizzes, action, setResult);
}

function* getQuizList(action) {
  yield call(callAPI, QuizAPI.getQuizList, action, setResult);
}

function* addEditPredefinedQuiz(action) {
  yield call(callAPI, QuizAPI.addEditPredefinedQuiz, action, action.cb);
}

function* onAdded() {
  yield put(push(RouteConstant.PREDEFINED_QUIZZES));
}

function* uploadQuestionFile(action) {
  yield call(callAPI, QuizAPI.uploadQuestionFile, action, setResult);
}

function* getPredefnedQuizDetails(action) {
  yield call(callAPI, QuizAPI.getPredefnedQuizDetails, action, setResult);
}

function* hostQuiz(action) {
  yield call(callAPI, QuizAPI.hostQuiz, action, action.cb);
}

function* getHostedQuizDetail(action) {
  yield call(callAPI, QuizAPI.getHostedQuizDetail, action, setResult);
}

function* startQuiz(action) {
  yield call(callAPI, QuizAPI.startQuiz, action, setResult, true);
}

function* finishQuiz(action) {
  yield call(callAPI, QuizAPI.finishQuiz, action, setResult, true);
}

function* getQuizParticipants(action) {
  yield call(callAPI, QuizAPI.getQuizParticipants, action, setResult, true);
}

function* getQuizParticipantRanks(action) {
  yield call(callAPI, QuizAPI.getQuizParticipantRanks, action, setResult, true);
}

function* validateQuizCode(action) {
  yield call(callAPI, QuizAPI.validateQuizCode, action, action.cb);
}

function* setResult(result) {
  yield put({ type: Quiz.QUIZ_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(Quiz.ADD_QUIZ_VIEW, addQuizView);
  yield takeLatest(Quiz.GET_PREDEFINED_QUIZZES, getPredefinedQuizzes);
  yield takeLatest(Quiz.GET_ALL_QUIZZES, getAllQuizzes);
  yield takeLatest(Quiz.GET_QUIZ_LIST, getQuizList);
  yield takeLatest(Quiz.ADD_EDIT_PREDEFINED_QUIZ, addEditPredefinedQuiz);
  yield takeLatest(Quiz.UPLOAD_QUESTION_FILE, uploadQuestionFile);
  yield takeLatest(Quiz.GET_PREDEFINED_QUIZ_DETAILS, getPredefnedQuizDetails);
  yield takeLatest(Quiz.HOST_QUIZ, hostQuiz);
  yield takeLatest(Quiz.GET_HOSTED_QUIZ_DETAIL, getHostedQuizDetail);
  yield takeLatest(Quiz.START_QUIZ, startQuiz);
  yield takeLatest(Quiz.FINISH_QUIZ, finishQuiz);
  yield takeLatest(Quiz.GET_QUIZ_PARTICIPANTS, getQuizParticipants);
  yield takeLatest(Quiz.GET_QUIZ_PARTICIPANT_RANKS, getQuizParticipantRanks);
  yield takeLatest(Quiz.VALIDATE_QUIZ_CODE, validateQuizCode);
}
