import APIUtil from "./APIUtil";

class AccountAPI {
  authUser(payload) {
    return APIUtil.callPostAPI("api/Login/Login", payload);
  }

  signUpParticipant(payload) {
    return APIUtil.callPostAPI("api/Login/SignUpParticipant", payload);
  }

  signUpUser(payload) {
    return APIUtil.callPostAPI("api/Login/SignUpUser", payload);
  }

  sendOtp(payload) {
    return APIUtil.callPostAPI("api/Login/SendOTP", payload);
  }

  validateUser(payload) {
    return APIUtil.callPostAPI("api/Login/ValidateOTP", payload);
  }

  signOut(payload) {
    return APIUtil.callPostAPI("api/Login/Logout", payload);
  }

  forgotPassword(payload) {
    return APIUtil.callPostAPI("api/Login/ForgotPassword", payload);
  }

  resetPassword(payload) {
    return APIUtil.callPostAPI("api/Login/ResetPassword", payload);
  }
}

export default new AccountAPI();
