import {
  finishQuiz,
  getHostedQuizDetail,
  getQuizParticipantRanks,
} from "actions/quiz";
import Timer from "common/Timer";
import { UserButton } from "components/Button";
import { Container } from "components/Card";
import { BarChart } from "components/Chart";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { AppConstant } from "utils/constant";
import { RouteConstant } from "utils/routeConstant";
import useToggle from "../../hooks/useToggle";

const ParticipantRankScreen = (props) => {
  const dispatch = useDispatch();
  const { quizHostedId } = useParams();
  const timerRef = useRef();
  const { participantRankList, hostedQuiz } = useSelector(
    (state) => state.quiz
  );
  const { quizStatus, quizName, totalQuizTime, totalScore, questionsCount } =
    hostedQuiz ? hostedQuiz : {};
  const [rankList, setRankList] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const { show: showPodium, toggle: togglePodium } = useToggle();

  useEffect(() => {
    dispatch(getHostedQuizDetail(quizHostedId));
    dispatch(getQuizParticipantRanks(quizHostedId));
  }, [quizHostedId]);

  useEffect(() => {
    if (participantRankList) {
      setRankList(
        participantRankList.map((c) => ({
          label: c.name,
          xData: c.score,
          score: c.score,
          colourCode: c.colourCode,
        }))
      );
      if (
        questionsCount &&
        participantRankList.every((c) => c.questionCount == questionsCount)
      ) {
        handleFinish();
      }
    }
  }, [participantRankList, questionsCount]);

  const handleFinish = useCallback(() => {
    dispatch(finishQuiz(quizHostedId));
    setIsCompleted(true);
  }, [quizHostedId]);

  if (showPodium) {
    return (
      <Navigate
        to={`${RouteConstant.QUIZ_PARTICIPANT_RESULT}/${quizHostedId}`}
      />
    );
  }

  return (
    <Container title={quizName}>
      {quizStatus == AppConstant.QUIZ_STATUS_COMPLETED || isCompleted ? (
        <>
          <div className="col-6">
            <h4>Game Completed</h4>
          </div>
          <div className="col-6">
            <UserButton
              text="Show Podium"
              onClick={togglePodium}
              className="btn btn-primary pull-right"
            />
          </div>
        </>
      ) : (
        <>
          <div className="timerWrapper">
            <Timer
              initialSeconds={totalQuizTime + AppConstant.QUIZ_INIT_TIME}
              onFinish={handleFinish}
              resetTime={totalQuizTime}
              ref={timerRef}
            />
          </div>
          <div className="col-12">
            <UserButton text="Finish" onClick={handleFinish} />
          </div>
        </>
      )}
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <BarChart
          data={rankList}
          label="Participants"
          minValue={0}
          maxValue={totalScore}
        />
      </div>
    </Container>
  );
};

export default ParticipantRankScreen;
