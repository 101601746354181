import { UserButton } from "components/Button";
import { UserCheckbox } from "components/Checkbox";
import { UserSelect } from "components/SelectBox";
import TextInput from "components/TextInput/TextInput";
import useForm from "hooks/useForm";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionDetails } from "actions/subscription";
import {
  addQuizView,
  uploadQuestionFile,
  getPredefinedQuiDetail,
} from "actions/quiz";
import { UploadFile } from "components/TextInput";
import useToast from "hooks/useToast";
import { QuizQuestions, QuizTemplate } from ".";
import useUserDetail from "hooks/useUserDetail";
import { Container } from "components/Card";

const AddQuizDetail = ({
  isQuizMaster,
  title,
  isHostingQuiz,
  quizId,
  questionRef,
  handleSave,
}) => {
  const dispatch = useDispatch();
  const { subscriptionTypeId: userSubscriptionType } = useUserDetail();
  const { subscriptionTypes, quizDetail } = useSelector((state) => state.quiz);
  const { subscriptionDetail } = useSelector((state) => state.subscription);
  const { showError } = useToast();
  const {
    state,
    handleChange,
    errors,
    handleSubmit,
    handleStateChange,
    setState,
  } = useForm({}, { displayName: { required: true } }, handleSave);
  const {
    subscriptionTypeId,
    defaultTimePerQuestion,
    maxNumberOfQuestionsInGame,
    maxNumberOfParticipantsInGame,
    displayName,
    randomQuestions,
    file,
    totalTime,
  } = state;
  const {
    maxNumberOfQuestionsInGame: maxQuestions,
    maxNumberOfParticipantsInGame: maxParticipants,
    allowQuestionsUpload,
  } = subscriptionDetail ? subscriptionDetail : {};

  useEffect(() => {
    dispatch(addQuizView());
    if (quizId) {
      dispatch(getPredefinedQuiDetail(quizId));
    }
  }, [quizId]);

  useEffect(() => {
    setState((prev) => ({
      totalTime: prev.totalTime,
      subscriptionTypeId: prev.subscriptionTypeId,
      ...quizDetail,
      ...subscriptionDetail,
    }));
  }, [quizDetail, subscriptionDetail]);

  useEffect(() => {
    if (subscriptionTypes && subscriptionTypes.length > 0) {
      if (isQuizMaster && userSubscriptionType) {
        onSubscriptionChange("subscriptionTypeId", userSubscriptionType);
      } else {
        handleChange("subscriptionTypeId", subscriptionTypes[0].value);
      }
    }
  }, [subscriptionTypes, userSubscriptionType]);

  useEffect(() => {
    if (maxNumberOfQuestionsInGame && defaultTimePerQuestion) {
      handleStateChange({
        totalTime: defaultTimePerQuestion * maxNumberOfQuestionsInGame,
      });
    } else {
      handleStateChange({ totalTime: null });
    }
  }, [maxNumberOfQuestionsInGame, defaultTimePerQuestion]);

  const onTimeChange = (name, value) => {
    handleStateChange({ [name]: value, defaultScorePerQuestion: value });
  };

  const onSubscriptionChange = (name, value) => {
    handleStateChange({ [name]: value });
    dispatch(getSubscriptionDetails(value));
  };

  const handleUpload = () => {
    if (!file) {
      showError("Please select file");
      return;
    }
    var formData = new FormData();
    formData.append("file", file.file);
    formData.append("fileName", file?.fileName);
    dispatch(uploadQuestionFile(formData));
  };

  return (
    <Container title={title ? title : "Create Pre-defined Games"}>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <TextInput
          name="displayName"
          value={displayName}
          label="Name of the Game"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      {!isQuizMaster && (
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <UserSelect
            name="subscriptionTypeId"
            value={subscriptionTypeId}
            options={subscriptionTypes}
            label="Accessible to"
            onChange={onSubscriptionChange}
            errors={errors}
          />
        </div>
      )}
      {isHostingQuiz && (
        <>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <TextInput
              name="defaultTimePerQuestion"
              value={defaultTimePerQuestion}
              type="float"
              maxLength={50}
              label="Default Time (Seconds)"
              onChange={onTimeChange}
              errors={errors}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <TextInput
              name="maxNumberOfQuestionsInGame"
              value={maxNumberOfQuestionsInGame}
              type="number"
              maxLength={50}
              label="Maximum Questions"
              onChange={handleChange}
              errors={errors}
              maxValue={isQuizMaster ? maxQuestions : null}
              maxValueError="Please upgrade your subscription"
            />
          </div>
          {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        <TextInput
          name="defaultScorePerQuestion"
          value={defaultScorePerQuestion}
          type="float"
          maxLength={50}
          label="Default Score"
          onChange={handleChange}
          errors={errors}
        />
      </div> */}
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <TextInput
              name="totalTime"
              value={totalTime}
              label="Total Time"
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <TextInput
              name="maxNumberOfParticipantsInGame"
              value={maxNumberOfParticipantsInGame}
              type="number"
              maxLength={50}
              label="Maximum Participants"
              onChange={handleChange}
              errors={errors}
              maxValue={isQuizMaster ? maxParticipants : null}
              maxValueError="Please upgrade your subscription"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 checkboxWrapper">
            <UserCheckbox
              name="randomQuestions"
              value={randomQuestions}
              onChange={handleChange}
              label="Random Questions"
            />
          </div>
        </>
      )}
      {!quizId && (!isQuizMaster || allowQuestionsUpload) && (
        <div className="col-12 flex-row mb-2">
          <div className="row">
            <div className="col-xl-4 col-md-4 col-sm-6 col-9">
              <UploadFile
                fileSrcName="file"
                label=" "
                onChange={handleChange}
                files={file}
                showFileName
              />
            </div>
            <div className="col-xl-1 col-md-2 col-sm-2 col-3">
              <UserButton text="Upload" onClick={handleUpload} />
            </div>
            <div className="col-xl-3 col-md-3 col-sm-4 col-9">
              <QuizTemplate />
            </div>
          </div>
        </div>
      )}
      {!isHostingQuiz && <QuizQuestions ref={questionRef} />}
      <div className="col-12 mt-2">
        <UserButton
          text={isHostingQuiz ? "Host" : "Save"}
          onClick={handleSubmit}
        />
      </div>
    </Container>
  );
};

export default AddQuizDetail;
