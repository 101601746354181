import APIUtil from "./APIUtil";

class SubscriptionAPI {
  subscriptionView(payload) {
    return APIUtil.callPostAPI("api/Subscription/SubscriptionView", payload);
  }

  getSubscriptionDetails(payload) {
    return APIUtil.callPostAPI(
      "api/Subscription/GetSubscriptionDetails",
      payload
    );
  }

  addEditSubscription(payload) {
    return APIUtil.callPostAPI("api/Subscription/AddEditSubscription", payload);
  }

  getSubscriptionFeatures(payload) {
    return APIUtil.callPostAPI(
      "api/Subscription/GetSubscriptionFeatures",
      payload
    );
  }

  upgradeSubscription(payload) {
    return APIUtil.callPostAPI("api/Subscription/UpgradeSubscription", payload);
  }
}

export default new SubscriptionAPI();
