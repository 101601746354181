import React, { useEffect, useState } from "react";
import TableCell from "./TableCell";

const Row = ({ row, rowI, headerOrder, rowId, onRowClick }) => {
  const onClick = () => {
    onRowClick(row.find((r) => r.key == rowId)?.text);
  };

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(row && row.find((r) => r.key == "selected")?.text);
  }, [row]);

  return (
    <tr
      className={`table-row${isSelected ? " selected" : ""}`}
      onClick={onRowClick ? onClick : null}
      {...row.find((r) => r.key == "rowProps")?.text}
    >
      {headerOrder.map((h, i) => (
        <TableCell data={row.find((r) => r.key === h)} key={`R${rowI}C${i}`} />
      ))}
    </tr>
  );
};

export default ({ data, meta, onRowClick, rowId }) => {
  const headerOrder = meta.map((m) => m.key);
  return (
    <tbody>
      {data.map((row, rowI) => (
        <Row
          row={row}
          rowI={rowI}
          headerOrder={headerOrder}
          onRowClick={onRowClick}
          rowId={rowId}
          key={`R${rowI}`}
        />
      ))}
    </tbody>
  );
};
