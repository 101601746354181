import APIUtil from "./APIUtil";

class QuizAPI {
  addQuizView(payload) {
    return APIUtil.callPostAPI("api/Quiz/AddQuizView", payload);
  }

  getPredefinedQuizzes(payload) {
    return APIUtil.callPostAPI("api/Quiz/GetPredefinedQuizzes", payload);
  }

  getAllQuizzes(payload) {
    return APIUtil.callPostAPI("api/Quiz/GetAllQuizzes", payload);
  }

  getQuizList(payload) {
    return APIUtil.callPostAPI("api/Quiz/GetQuizList", payload);
  }

  addEditPredefinedQuiz(payload) {
    return APIUtil.callPostAPI("api/Quiz/AddEditPredefinedQuiz", payload);
  }

  uploadQuestionFile(payload) {
    return APIUtil.callPostAPI("api/Quiz/UploadQuizFile", payload);
  }

  getPredefnedQuizDetails(payload) {
    return APIUtil.callPostAPI("api/Quiz/GetPredefinedQuizDetails", payload);
  }

  hostQuiz(payload) {
    return APIUtil.callPostAPI("api/Quiz/HostQuiz", payload);
  }

  getHostedQuizDetail(payload) {
    return APIUtil.callPostAPI("api/Quiz/GetHostedQuizDetail", payload);
  }

  startQuiz(payload) {
    return APIUtil.callPostAPI("api/Quiz/StartQuiz", payload);
  }

  finishQuiz(payload) {
    return APIUtil.callPostAPI("api/Quiz/FinishQuiz", payload);
  }

  getQuizParticipants(payload) {
    return APIUtil.callPostAPI("api/Quiz/GetQuizParticipants", payload);
  }

  getQuizParticipantRanks(payload) {
    return APIUtil.callPostAPI("api/Quiz/GetQuizParticipantRanks", payload);
  }

  validateQuizCode(payload) {
    return APIUtil.callPostAPI("api/Quiz/ValidateQuizCode", payload);
  }
}

export default new QuizAPI();
