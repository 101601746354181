import { Report } from "actionTypes/report";

export const reportView = (payload) => ({
  type: Report.REPORT_VIEW,
  payload,
});

export const getQuizMasterList = (payload) => ({
  type: Report.GET_QUIZ_MASTER_LIST,
  payload,
});

export const getParticipantList = (payload) => ({
  type: Report.GET_PARTICIPANT_LIST,
  payload,
});

export const getHostedQuizList = (payload) => ({
  type: Report.GET_HOSTED_QUIZ_LIST,
  payload,
});

export const getQuizParticipantDetails = (payload, cb) => ({
  type: Report.GET_QUIZ_PARTICIPANT_DETAILS,
  payload,
  cb,
});
