import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

const Timer = ({ initialSeconds, resetTime, onFinish }, ref) => {
  const [seconds, setSeconds] = useState(null);

  useEffect(() => {
    setSeconds(initialSeconds);
  }, [initialSeconds, resetTime]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (seconds == 0 && onFinish) {
      onFinish();
    }
  }, [seconds]);

  useImperativeHandle(ref, () => ({
    getTimeRemaining() {
      return seconds;
    },
  }));

  return <div className="timer">{seconds}</div>;
};

export default forwardRef(Timer);
