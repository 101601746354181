import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUpParticipant } from "actions/account";
import { TextInput } from "components/TextInput";
import { UserButton } from "components/Button";
import useForm from "hooks/useForm";
import { RouteConstant } from "utils/routeConstant";
import { useNavigate, useParams } from "react-router-dom";
import { UserContainer } from "components/Container";

const SignUpScreen = () => {
  const dispatch = useDispatch();
  const { quizHostedId } = useParams();
  const { state, errors, handleChange, handleSubmit } = useForm(
    {},
    {
      displayName: { required: true },
    },
    handleSignUp
  );
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.account);
  const { displayName } = state;

  useEffect(() => {
    if (isLoggedIn) {
      if (quizHostedId) {
        navigate(`${RouteConstant.USER_QUIZ_QUESTIONS}/${quizHostedId}`);
      } else {
        navigate(RouteConstant.QUIZ_CODE);
      }
    }
  }, [isLoggedIn]);

  function handleSignUp(payload) {
    dispatch(signUpParticipant({ ...payload, quizHostedId }));
  }

  return (
    <UserContainer>
      <div className="centerAlign">
        <div className="item">
          <label labelfor="answer_2">
            <TextInput
              name="displayName"
              value={displayName}
              maxLength={50}
              asterix
              placeholder="Name"
              onChange={handleChange}
              errors={errors}
            />
            <p></p>
            <p>
              <UserButton
                type="submit"
                text="Join the quiz."
                className="forward"
                icon={<span className="icon">{">"}</span>}
                onClick={handleSubmit}
              />
            </p>
            <p>
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/tif.png`}
                alt=""
              />
            </p>
          </label>
        </div>
      </div>
    </UserContainer>
  );
};

export default SignUpScreen;
