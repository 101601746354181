import { takeLatest, call, put } from "redux-saga/effects";
import SubscriptionAPI from "api/SubscriptionAPI";
import { Subscription } from "actionTypes/subscription";
import callAPI from "./helper";
import { Account } from "actionTypes/account";

function* subscriptionView(action) {
  yield call(callAPI, SubscriptionAPI.subscriptionView, action, setResult);
}

function* addEditSubscription(action) {
  yield call(callAPI, SubscriptionAPI.addEditSubscription, action, setResult);
}

function* getSubscriptionDetails(action) {
  yield call(
    callAPI,
    SubscriptionAPI.getSubscriptionDetails,
    action,
    setResult
  );
}

function* getSubscriptionFeatures(action) {
  yield call(
    callAPI,
    SubscriptionAPI.getSubscriptionFeatures,
    action,
    setResult
  );
}

function* upgradeSubscription(action) {
  yield call(
    callAPI,
    SubscriptionAPI.upgradeSubscription,
    action,
    updateUserDetail
  );
}

function* updateUserDetail(result) {
  yield put({ type: Account.UPDATE_USER_DATA, result });
}

function* setResult(result) {
  yield put({ type: Subscription.SUBSCRIPTION_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(Subscription.SUBSCRIPTION_VIEW, subscriptionView);
  yield takeLatest(Subscription.ADD_EDIT_SUBSCRIPTION, addEditSubscription);
  yield takeLatest(
    Subscription.GET_SUBSCRIPTION_FEATURES,
    getSubscriptionFeatures
  );
  yield takeLatest(
    Subscription.GET_SUBSCRIPTION_DETAILS,
    getSubscriptionDetails
  );
  yield takeLatest(Subscription.UPGRADE_SUBSCRIPTION, upgradeSubscription);
}
