import { getPredefinedQuizzes } from "actions/quiz";
import { Container } from "components/Card";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QuizGrid } from "./Components";

export default () => {
  const dispatch = useDispatch();
  const { quizList } = useSelector((state) => state.quiz);

  useEffect(() => {
    dispatch(getPredefinedQuizzes(null));
  }, []);

  return (
    <Container title="Pre-defined Games">
      <QuizGrid quizList={quizList} />
    </Container>
  );
};
