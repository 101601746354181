import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { persistStore, persistReducer } from "redux-persist";
import { createReduxHistoryContext } from "redux-first-history";
import storage from "redux-persist/lib/storage";
import rootReducer from "reducers";
import rootSaga from "sagas";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

export default () => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, erroInfo) => {
      if (error.status == 401) {
        // store.dispatch({type:Account.SIGN_OUT});
      }
    },
  });

  const persistConfig = {
    key: "eliveRoot",
    storage,
    whitelist: ["account"],
  };

  const middleware = [sagaMiddleware, routerMiddleware];

  const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
      router: routerReducer,
      ...rootReducer,
    })
  );

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false, thunk: false }).concat(
        middleware
      ),
  });

  sagaMiddleware.run(rootSaga);
  const history = createReduxHistory(store);

  return {
    store,
    history,
    persistor: persistStore(store),
  };
};
