import { getQuizMasterList } from "actions/report";
import { Container } from "components/Card";
import { ExportCSV } from "components/Document";
import { UserTable } from "components/Table";
import { TextInput } from "components/TextInput";
import useForm from "hooks/useForm";
import { containsString } from "libs/functions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const headers = [
  {
    key: "displayName",
    text: "Name",
  },
  {
    key: "emailAddress",
    text: "Email",
  },
  {
    key: "contactNumber",
    text: "Contact Number",
  },
  {
    key: "subscriptionType",
    text: "Subscription Type",
  },
];

const QuizMasterList = () => {
  const dispatch = useDispatch();
  const { quizMasterList } = useSelector((state) => state.report);
  const [gridData, setGridData] = useState([]);
  const { state, handleChange } = useForm({});
  const { searchBy } = state;

  useEffect(() => {
    if (quizMasterList) {
      let gridData = quizMasterList;
      if (searchBy && gridData && gridData.length > 0) {
        const keys = Object.keys(gridData[0]);
        gridData = gridData.filter((q) =>
          keys.some((key) => containsString(q[key], searchBy))
        );
      }
      setGridData(gridData);
    }
  }, [quizMasterList, searchBy]);

  useEffect(() => {
    dispatch(getQuizMasterList());
  }, []);

  return (
    <Container title="Quiz Masters">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <TextInput
            name="searchBy"
            value={searchBy}
            onChange={handleChange}
            placeholder="Search By"
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2">
          <div className="pull-right">
            <ExportCSV
              btnText="Download"
              filename="Quiz_Master.csv"
              headers={headers.map((c) => ({ ...c, label: c.text }))}
              data={gridData}
            />
          </div>
        </div>
      </div>
      <UserTable headers={headers} data={gridData} />
    </Container>
  );
};

export default QuizMasterList;
