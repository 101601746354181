import { takeLatest, call, put } from "redux-saga/effects";
import AccountAPI from "api/AccountAPI";
import { Account } from "actionTypes/account";
import callAPI from "./helper";
import auth from "libs/auth";
import { push } from "redux-first-history";
import { RouteConstant } from "utils/routeConstant";

function* authUser(action) {
  yield call(callAPI, AccountAPI.authUser, action, setResult);
}

function* signUpParticipant(action) {
  yield call(callAPI, AccountAPI.signUpParticipant, action, setResult);
}

function* signUpUser(action) {
  yield call(callAPI, AccountAPI.signUpUser, action, action.cb);
}

function* sendNewUserOtp(action) {
  yield call(callAPI, AccountAPI.sendOtp, action, action.cb);
}

function* validateUser(action) {
  yield call(callAPI, AccountAPI.validateUser, action, action.cb);
}

function* forgotPassword(action) {
  yield call(callAPI, AccountAPI.forgotPassword, action);
}

function* resetPassword(action) {
  yield call(callAPI, AccountAPI.resetPassword, action, action.cb);
}

function* signOut(action) {
  yield call(callAPI, AccountAPI.signOut, action, onSignOut);
}

function* onSignOut(result) {
  yield put({ type: Account.SIGN_OUT_SUCCESS });
  // yield put(push(RouteConstant.LOGIN));
}

function* setResult({ token, userData, ...rest }) {
  auth.signIn(token);
  yield put({ type: Account.AUTH_SUCCESS, result: { userData, ...rest } });
  // yield put(push(RouteConstant.PREDEFINED_QUIZZES));
}

export default function* rootSaga() {
  yield takeLatest(Account.AUTH_USER, authUser);
  yield takeLatest(Account.SIGN_UP_PARTICIPANT, signUpParticipant);
  yield takeLatest(Account.SIGN_UP_USER, signUpUser);
  yield takeLatest(Account.SEND_NEW_USER_OTP, sendNewUserOtp);
  yield takeLatest(Account.VALIDATE_USER, validateUser);
  yield takeLatest(Account.FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(Account.RESET_PASSWORD, resetPassword);
  yield takeLatest(Account.SIGN_OUT, signOut);
}
