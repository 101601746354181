import { Subscription } from "actionTypes/subscription";

export const subscriptionView = (payload) => ({
  type: Subscription.SUBSCRIPTION_VIEW,
  payload,
});

export const getSubscriptionDetails = (payload) => ({
  type: Subscription.GET_SUBSCRIPTION_DETAILS,
  payload,
});

export const addEditSubscription = (payload) => ({
  type: Subscription.ADD_EDIT_SUBSCRIPTION,
  payload,
});

export const getSubscriptionFeatures = (payload) => ({
  type: Subscription.GET_SUBSCRIPTION_FEATURES,
  payload,
});

export const upgradeSubscription = (payload) => ({
  type: Subscription.UPGRADE_SUBSCRIPTION,
  payload,
});
