import { getHostedQuizList, getParticipantList } from "actions/report";
import { Container } from "components/Card";
import { UserSelect } from "components/SelectBox";
import useForm from "hooks/useForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ParticipantList } from "./Components";

const ParticipantListScreen = () => {
  const dispatch = useDispatch();
  const { hostedQuizList } = useSelector((state) => state.report);
  const { state, handleChange } = useForm({});
  const { hostedQuizId } = state;

  useEffect(() => {
    if (hostedQuizList && hostedQuizList.length > 0) {
      handleChange("hostedQuizId", hostedQuizList[0].value);
    }
  }, [hostedQuizList]);

  useEffect(() => {
    dispatch(getHostedQuizList());
  }, []);

  useEffect(() => {
    if (hostedQuizId) dispatch(getParticipantList(hostedQuizId));
  }, [hostedQuizId]);

  return (
    <Container title="Game Participants">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <UserSelect
            name="hostedQuizId"
            value={hostedQuizId}
            options={hostedQuizList}
            label="Games"
            onChange={handleChange}
          />
        </div>
        <ParticipantList />
      </div>
    </Container>
  );
};

export default ParticipantListScreen;
