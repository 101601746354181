import React from "react";
import { UserLabel } from "../Label";
import "./_checkbox.scss";

const Input = (props) => {
  const { name, className, disabled, onChange, value, error } = props;

  return (
    <>
      <input
        id={name}
        type="checkbox"
        checked={value ? true : false}
        name={`${name}`}
        onChange={onChange}
        className={`custom-control-input${error ? " errInput" : ""}`}
        disabled={disabled}
      />
      <span></span>
    </>
  );
};

const UserCheckbox = (props) => {
  const onFieldChange = (event) => {
    const { onChange, value, name, disabled } = props;
    if (!disabled) onChange(name, !value);
  };

  const { errors, name, label } = props;
  const error = errors ? errors[name] : null;

  return (
    <div className="form-group form-md-checkboxes">
      <div className="md-checkbox">
        <Input {...props} onChange={onFieldChange} error={error} />
        <label htmlFor={name}>
          <span></span>
          <span className="check"></span>
          <span className="box"></span>
          {label}
        </label>
      </div>
    </div>
  );
};

export default UserCheckbox;
