import React from "react";

const LoginWrapper = ({ title, children }) => {
  return (
    <div className="login">
      <div className="content">
        <div className="logo">
          <a href="#">
            <img src={`${process.env.PUBLIC_URL}/roimoi-logo.png`} alt="" />
          </a>
        </div>
        <form method="post" className="login-form">
          <h3 className="form-title">{title}</h3>
          {children}
        </form>
      </div>
    </div>
  );
};

export default LoginWrapper;
