import { takeLatest, call, put } from "redux-saga/effects";
import ReportAPI from "api/ReportAPI";
import { Report } from "actionTypes/report";
import callAPI from "./helper";

function* reportView(action) {
  yield call(callAPI, ReportAPI.reportView, action, setResult);
}

function* getQuizMasterList(action) {
  yield call(callAPI, ReportAPI.getQuizMasterList, action, setResult);
}

function* getParticipantList(action) {
  yield call(callAPI, ReportAPI.getParticipantList, action, setResult);
}

function* getHostedQuizList(action) {
  yield call(callAPI, ReportAPI.getHostedQuizList, action, setResult);
}

function* getQuizParticipantDetails(action) {
  yield call(callAPI, ReportAPI.getQuizParticipantDetails, action, action.cb);
}

function* setResult(result) {
  yield put({ type: Report.REPORT_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(Report.REPORT_VIEW, reportView);
  yield takeLatest(Report.GET_QUIZ_MASTER_LIST, getQuizMasterList);
  yield takeLatest(Report.GET_PARTICIPANT_LIST, getParticipantList);
  yield takeLatest(Report.GET_HOSTED_QUIZ_LIST, getHostedQuizList);
  yield takeLatest(
    Report.GET_QUIZ_PARTICIPANT_DETAILS,
    getQuizParticipantDetails
  );
}
