import React from "react";
import DatePicker from "react-date-picker";
import moment from "moment";
import { UserError, UserLabel } from "../Label";
import { FcCalendar } from "react-icons/fc";
import useToggle from "../../hooks/useToggle";
import { AppConstant } from "../../utils/constant";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./_userDatePicker.scss";

const getDate = (date) => {
  return date
    ? moment(date, [
        AppConstant.API_DATE_FORMAT,
        "YYYY-MM-DD hh:mm:ss",
        "DDMMMYYYY",
      ]).toDate()
    : undefined;
};

const UserDatePicker = ({
  value,
  onChange,
  className,
  maxDate,
  minDate,
  disabled,
}) => {
  const { show, setShow } = useToggle(false);

  const onClick = () => {
    setShow(true);
  };

  const handleChange = (date) => {
    onChange(date);
    setShow(false);
  };

  return (
    <DatePicker
      calendarIcon={<FcCalendar />}
      isOpen={show}
      value={getDate(value)}
      onChange={handleChange}
      className={className ? `${className} form-control` : "form-control"}
      locale="en-GB"
      format="d/M/yyyy"
      maxDate={getDate(maxDate)}
      minDate={getDate(minDate)}
      autoComplete={false}
      disabled={disabled}
    />
  );
};

export default (props) => {
  const { onChange, errors, name, label } = props;

  const handleChange = (date) => {
    date = date ? moment(date).format(AppConstant.API_DATE_FORMAT) : date;
    onChange(name, date);
  };

  const error = errors ? errors[name] : null;

  return (
    <div
      className={`datePicker form-group${label ? " form-md-line-input" : ""}`}
    >
      <UserDatePicker {...props} onChange={handleChange} />
      {label && <UserLabel {...props} />}
      <UserError error={error} />
    </div>
  );
};
