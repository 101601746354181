import APIUtil from "./APIUtil";

class ReportAPI {
  reportView(payload) {
    return APIUtil.callPostAPI("api/Subscription/DashboardView", payload);
  }

  getQuizMasterList(payload) {
    return APIUtil.callPostAPI("api/Subscription/GetQuizMasterList", payload);
  }

  getParticipantList(payload) {
    return APIUtil.callPostAPI("api/Subscription/GetParticipantList", payload);
  }

  getHostedQuizList(payload) {
    return APIUtil.callPostAPI("api/Subscription/GetHostedQuizList", payload);
  }

  getQuizParticipantDetails(payload) {
    return APIUtil.callPostAPI(
      "api/Subscription/GetQuizParticipantDetails",
      payload
    );
  }
}

export default new ReportAPI();
