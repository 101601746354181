import React from "react";
import { AiOutlineFileExcel } from "react-icons/ai";
import { CSVLink } from "react-csv";

export default ({ data, filename, headers, onClick, btnText }) => {
  return (
    <CSVLink
      headers={headers}
      data={data}
      filename={filename}
      onClick={onClick ? onClick : null}
      className="btn btn-primary btn-sm fr"
      target="_blank"
    >
      {btnText} <AiOutlineFileExcel />
    </CSVLink>
  );
};
