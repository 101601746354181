import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import UserAPI from "api/UserAPI";
import { User } from "actionTypes/user";
import callAPI from "./helper";

function* addEditQuizQuestion(action) {
  yield call(callAPI, UserAPI.addEditQuizQuestion, action, setResult, true);
}

function* getQuizQuestion(action) {
  yield call(callAPI, UserAPI.getQuizQuestion, action, setResult, true);
}

function* sendOTP(action) {
  yield call(callAPI, UserAPI.sendOTP, action, action.cb);
}

function* validateOTP(action) {
  yield call(callAPI, UserAPI.validateOTP, action, action.cb);
}

function* setResult(result, payload, cb) {
  yield put({ type: User.USER_SUCCESS, result });
  if (cb) {
    cb(result);
  }
}

export default function* rootSaga() {
  yield takeEvery(User.ADD_EDIT_QUIZ_QUESTION, addEditQuizQuestion);
  yield takeEvery(User.GET_QUIZ_QUESTION, getQuizQuestion);
  yield takeLatest(User.SEND_OTP, sendOTP);
  yield takeLatest(User.VALIDATE_OTP, validateOTP);
}
