import progress from "./progress";
import response from "./response";
import account from "./account";
import subscription from "./subscription";
import quiz from "./quiz";
import user from "./user";
import report from "./report";

export default {
  account,
  progress,
  response,
  subscription,
  quiz,
  user,
  report,
};
